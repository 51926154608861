<head>
    <meta name="author" content="Christina Speck">
</head>
<div fxLayout="row">
    <!-- Registration data forms and Buttons -->
    <div class="registerdata" fxFlex="50%">
        <img class="logo" src="assets/MatgramLogo.png" width="100px"/>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <h3 class="heading-username" i18n>{{ 'REGISTER.username' | translate }}</h3>
                <p class="username-info" i18n> {{'REGISTER.usernameInfo' | translate}}</p>
                <mat-form-field id="username" class="register-form-field">
                    <mat-label i18n>{{ 'REGISTER.username' | translate }}</mat-label>
                    <input matInput formControlName="username"/>
                    <mat-error *ngIf="f.username.invalid" i18n>
                        {{ 'REGISTER.usernameInvalid' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="password" class="register-form-field">
                    <mat-label i18n>{{ 'REGISTER.password' | translate }}</mat-label>
                    <input
                            matInput
                            type="password"
                            placeholder="Password"
                            formControlName="password"
                    />
                    <mat-error *ngIf="f.password.invalid">
                        <p *ngIf="f.password.errors.required" i18n>{{ 'REGISTER.passwordInvalid' | translate }}</p>
                        <p *ngIf="f.password.errors.minlength" i18n>
                            {{ 'REGISTER.passwordMinLength' | translate }}
                        </p>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field id="password-repeat" class="register-form-field">
                    <mat-label i18n>{{ 'REGISTER.repeatPassword' | translate }}</mat-label>
                    <input
                            matInput
                            type="password"
                            placeholder="Repeat password"
                            formControlName="passwordRepeat"
                            [errorStateMatcher]="matcher"
                    />
                    <mat-error *ngIf="formErrors?.passwordsnotmatching" i18n>
                        {{ 'REGISTER.passwordsNotMatching' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <button
                    class="register-form-field-button"
                    [disabled]="loading || hasFormErrors"
                    mat-raised-button
                    color="primary"
                    i18n
            >
                {{ 'REGISTER.register' | translate }}
            </button>
        </form>
        <a routerLink="" i18n>
            <button class="register-form-field-button"
                    mat-raised-button
                    color="primary">
                {{ 'REGISTER.cancel' | translate }}
            </button>
        </a>
    </div>

    <!-- Mategram info text -->
    <div class="infotextRegister" fxFlex="50%">
        <div class="carousel-style">
            <div id="demo" class="carousel slide" data-ride="carousel" data-interval="8000">
                <ul class="carousel-indicators">
                    <li data-target="#demo" data-slide-to="0" class="active"></li>
                    <li data-target="#demo" data-slide-to="1"></li>
                    <li data-target="#demo" data-slide-to="2"></li>
                    <li data-target="#demo" data-slide-to="3"></li>
                    <li data-target="#demo" data-slide-to="4"></li>
                </ul>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100" src="..." alt=" " width="1100" height="500">
                        <div class="carousel-caption">
                            <div class="infotextHeader mat-h4" i18n>{{ 'REGISTER.infoTextHeader' | translate }}</div>
                            <p class="mat-body" i18n [innerHTML]="'REGISTER.infoText' | translate">
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src="..." alt=" " width="1100" height="500">
                        <div class="carousel-caption">
                            <div class="infotextHeader mat-h4" i18n>{{ 'REGISTER.humanQuestion' | translate }}</div>
                            <p class="mat-body" i18n [innerHTML]="'REGISTER.humanAnswer' | translate">
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src="..." alt=" " width="1100" height="500">
                        <div class="carousel-caption">
                            <div class="infotextHeader mat-h4" i18n>{{ 'REGISTER.carefulPassword' | translate }}</div>
                            <p class="mat-body" i18n [innerHTML]="'REGISTER.carefulPasswordText' | translate">
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src="..." alt=" " width="1100" height="500">
                        <div class="carousel-caption">
                            <div class="infotextHeader mat-h4" i18n>{{ 'REGISTER.getRewarded' | translate }}</div>
                            <p class="mat-body" i18n [innerHTML]="'REGISTER.getRewardedText' | translate">
                            </p>
                            <ul>
                                <li><p class="mat-body" i18n>{{ 'REGISTER.getRewardedListItem1' | translate }}</p></li>
                                <li><p class="mat-body" i18n>{{ 'REGISTER.getRewardedListItem2' | translate }}</p></li>
                                <li>
                                    <p class="mat-body" i18n>
                                        {{ 'REGISTER.getRewardedListItem3' | translate }}
                                    </p>
                                </li>
                            </ul>
                            <p class="mat-body" i18n [innerHTML]="'REGISTER.getRewardedEndText' | translate">
                            </p>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src="..." alt=" " width="1100" height="500">
                        <div class="carousel-caption">
                            <div class="infotextHeader mat-h4" i18n>{{ 'REGISTER.rules' | translate }}</div>
                            <p class="mat-body" i18n [innerHTML]="'REGISTER.rulesText' | translate">
                            </p>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </a>
            </div>
        </div>
    </div>
</div>
