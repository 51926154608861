<body>
  <div class="searchbar">
    <button mat-icon-button><mat-icon>search</mat-icon></button>
    <mat-form-field id="searchTermInput">
      <input
        matInput
        placeholder="{{ 'SEARCH.searchForFriends' | translate }}"
        #term
        (keyup)="onSearchTermChange()"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="100px" id="wrapper">
    <div fxFlex="50%">
      <div fxFlex>
        <mat-spinner id="spinner" *ngIf="inProgress"></mat-spinner>

        <div id="nothingFound" *ngIf="nothingFound">
          <p class="mat-title" [translate]="'SEARCH.nothingFound'" [translateParams]="{name: term.value}">
          </p>
        </div>
        <app-post
          *ngFor="let item of matches; let i = index"
          [post]="matches[i]"
          [currentUser]="currentUser.username"
          (postUpdated)="onPostUpdated()"
        ></app-post>
      </div>
    </div>
  </div>
</body>
