import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Post} from '../../models/post.model';
import {UploadService} from 'src/app/services/upload.service';
import {MatDialog} from '@angular/material/dialog';
import {DeletePostDialogComponent} from '../delete-post/delete-post.dialog';
import {ActivityService} from 'src/app/services/activity.service';
import {PostService} from 'src/app/services/postAction.service';
import {MultipleProfileService} from 'src/app/services/multipleProfile.service';
import {AlertService} from 'src/app/services/alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PostComponent {
  @Input() post: Post;
  @Input() currentUser: string;
  @Output() postUpdated = new EventEmitter<Post>();

  constructor(
    private uploadService: UploadService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activityService: ActivityService,
    private postService: PostService,
    private multipleProfiles: MultipleProfileService,
    private alertService: AlertService
  ) {
  }

  profileSelected = () => {
      this.multipleProfiles.selectUserProfile(this.post.username);
  };

  isDemonetized = () => this.post.demonetized;

  isMyPost = () => this.post.username === this.currentUser;

  upvotedByMe = () => {
      if (this.post.votes.length > 0) {
          return this.post.votes.find(v => v.username === this.currentUser && v.upvoted);
      } else {
      // Don't return [] here, [] is truthy
          return false;
      }
  };

  downvotedByMe = () => {
      if (this.post.votes.length > 0) {
          return this.post.votes.find(v => v.username === this.currentUser && !v.upvoted);
      } else {
      // Don't return [] here, [] is truthy
          return false;
      }
  };

  // A user can vote if this is not his post and if he hasn't done that before
  checkVotable = () => {
      if (this.upvotedByMe()) {
          this.snackBar.open('You cannot upvote twice!', 'OK', {
              duration: 4000
          });
          return false;
      } else if (this.downvotedByMe()) {
          this.snackBar.open('You cannot downvote twice!', 'OK', {
              duration: 4000
          });
          return false;
      } else if (this.isMyPost()) {
          this.snackBar.open('You cannot vote your own post!', 'OK', {
              duration: 4000
          });
          return false;
      } else {
          return true;
      }
  };

  onUpvoteClick = () => {
      if (this.checkVotable()) {
          if (this.uploadService.upvotePost(this.post)) {
              // TODO: Activity record
              this.postUpdated.emit(this.post);
          }
      }
  };


  onDownvoteClick = () => {
      if (this.checkVotable()) {
          if (this.uploadService.downvotePost(this.post)) {
              // TODO: Activity record
              this.postUpdated.emit(this.post);
          }
      }
  };

  // A user can delete a post. However, it is still visible for other users on the blockchain
  onDeleteClick = () => {
      const deleteDialog = this.dialog.open(DeletePostDialogComponent, {
          width: '500px',
          data: {
              message:
          'If you delete this post, it becomes invisible for you.' +
          ' However, it stays visible for all other users as it is already saved inside the Blockchain.'
          }
      });
      deleteDialog.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
              this.postService.deletePost(this.post);
              this.postUpdated.emit(this.post);
              // TODO: activity record
              console.log('User confirmed deletion');
          }
      });
  };

  getUpvotesCount() {
      return this.post.votes.filter(v => v.upvoted).length;
  }

  getDownvotesCount() {
      return this.post.votes.filter(v => !v.upvoted).length;
  }
}

