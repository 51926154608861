/* eslint-disable max-len,prefer-arrow/prefer-arrow-functions */
/*!
 * A refactored & improved TypeScript version of the Mus.js library.
 */

import html2canvas from 'html2canvas';

export type ScrollFrame = [frameType: 's', timePoint: number, scrollLeft: number, scrollRight: number];
export type InputFrame = [frameType: 'i', timePoint: number, elementXPath: string, key: string];
export type SelectFrame = [frameType: 'o', timePoint: number, elementXPath: string, value: string];
export type MoveFrame = [frameType: 'm', timePoint: number, x: number, y: number];
export type ClickFrame = [frameType: 'c', timePoint: number, x: number, y: number];
export type ButtonClickFrame = [frameType: 'b', timePoint: number, x: number, y: number, transition: string, id: string];
export type Frame = ScrollFrame | InputFrame | SelectFrame | MoveFrame | ClickFrame | ButtonClickFrame;

export type Screenshot = [screenshot: string, timePoint: number, width: number, height: number, scaleFactor: number, triggeredBy: string];

export interface MusRectangle {
    height: number;
    width: number;
}

export interface MusCanvas {
    canvasString: string;
    size: MusRectangle;
}

export interface MusTrial {
    frames: Array<Frame>;
    screenshots: Array<Screenshot>;
    timeElapsed: number;
    timestamp: number;
    windowSize: MusRectangle;
    scrollSize: MusRectangle;
}

// helper functions Xpath <-> Element object
function getXpathFromElement(elm): string {
    let segs = [];
    const allNodes = document.getElementsByTagName('*');
    for (segs = []; elm && elm.nodeType === 1; elm = elm.parentNode) {
        let i;
        let sib;
        for (; sib; sib = sib.previousSibling) {
            if (sib.localName === elm.localName) {
                i++;
            }
        }
        segs.unshift(elm.localName.toLowerCase() + '[' + i + ']');
    }
    return segs.length ? '/' + segs.join('/') : null;
}

function getElementByXpath(path): HTMLElement {
    const evaluator = new XPathEvaluator();
    const result = evaluator.evaluate(path, document.documentElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
    return result.singleNodeValue as HTMLElement;
}

export class Mus {
    static cursorIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCSB2aWV3Qm94PSIwIDAgMjggMjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI4IDI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZGRkZGIiBwb2ludHM9IjguMiwyMC45IDguMiw0LjkgMTkuOCwxNi41IDEzLDE2LjUgMTIuNiwxNi42ICIvPjxwb2x5Z29uIGZpbGw9IiNGRkZGRkYiIHBvaW50cz0iMTcuMywyMS42IDEzLjcsMjMuMSA5LDEyIDEyLjcsMTAuNSAiLz48cmVjdCB4PSIxMi41IiB5PSIxMy42IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjkyMjEgLTAuMzg3MSAwLjM4NzEgMC45MjIxIC01Ljc2MDUgNi41OTA5KSIgd2lkdGg9IjIiIGhlaWdodD0iOCIvPjxwb2x5Z29uIHBvaW50cz0iOS4yLDcuMyA5LjIsMTguNSAxMi4yLDE1LjYgMTIuNiwxNS41IDE3LjQsMTUuNSAiLz48L3N2Zz4=';
    private frames: Array<Frame> = [];
    private screenshots: Array<Screenshot> = [];
    private timeouts: Array<any> = [];
    private pos = 0;
    private currPos = 0;
    private startedAt = 0;
    private finishedAt = 0;
    private recording = false;
    private playing = false;
    private observers: MutationObserver[] = [];
    private windowSize: MusRectangle;
    private scrollSize: MusRectangle;
    private scaleFactor = window.devicePixelRatio;

    // Stores initial listeners
    private onmousemove = window.onmousemove;
    private onmousedown = window.onmousedown;
    private onscroll = window.onscroll;
    private onkeypress = window.onkeypress;
    private onresize = window.onresize;

    private static createCursor(): HTMLDivElement {
        const existingCursor = document.getElementById('musCursor');
        if (!existingCursor) {
            const node = document.createElement('div');
            node.id = 'musCursor';
            node.style.position = 'fixed';
            node.style.width = '32px';
            node.style.height = '32px';
            node.style.top = '-100%';
            node.style.left = '-100%';
            node.style.borderRadius = '32px';
            node.style.backgroundImage = 'url(' + Mus.cursorIcon + ')';
            document.body.appendChild(node);
            return node;
        } else {
            return existingCursor as HTMLDivElement;
        }
    }

    private static destroyCursor() {
        const cursor = document.getElementById('musCursor');
        if (cursor) {
            cursor.remove();
        }
    }

    private static createClickSnapshot(x, y) {
        const left = document.scrollingElement.scrollLeft;
        const top = document.scrollingElement.scrollTop;
        const node = document.createElement('div');
        node.className = 'musClickSnapshot';
        node.style.position = 'absolute';
        node.style.width = '32px';
        node.style.height = '32px';
        node.style.top = (x + top) + 'px';
        node.style.left = (y + left) + 'px';
        node.style.borderRadius = '32px';
        node.style.backgroundColor = 'red';
        node.style.opacity = '0.2';
        document.body.appendChild(node);
    }

    private static createButtonClickSnapshot(x, y) {
        const left = document.scrollingElement.scrollLeft;
        const top = document.scrollingElement.scrollTop;
        const node = document.createElement('div');
        node.className = 'musClickSnapshot';
        node.style.position = 'absolute';
        node.style.width = '40px';
        node.style.height = '40px';
        node.style.top = (x + top) + 'px';
        node.style.left = (y + left) + 'px';
        node.style.borderRadius = '40px';
        node.style.backgroundColor = 'blue';
        node.style.opacity = '0.2';
        // node.innerText = 'Button Click';
        document.body.appendChild(node);
    }

    private static destroyClickSnapshot() {
        const nodes = document.getElementsByClassName('musClickSnapshot');
        while (nodes.length > 0) {
            nodes[0].remove();
        }
    }

    private static async makeScreenshot(options: Record<string, unknown> = {}, screenshotTarget = document.body): Promise<MusCanvas> {
        const canvas = await html2canvas(screenshotTarget, options);
        return {
            canvasString: canvas.toDataURL(),
            size: {
                width: canvas.width,
                height: canvas.height,
            }
        };
    }

    getData(): MusTrial {
        return {
            frames: this.frames,
            screenshots: this.screenshots,
            timeElapsed: this.timeElapsed(),
            timestamp: this.startedAt,
            windowSize: this.windowSize,
            scrollSize: this.scrollSize
        };
    }

    setData(data: MusTrial) {
        if (data.frames) {
            this.frames = data.frames;
        }
        if (data.windowSize) {
            this.windowSize = data.windowSize;
        }
    }

    setScaleFactor(scaleFactor: number) {
        this.scaleFactor = scaleFactor;
    }

    isRecording(): boolean {
        return this.recording;
    }

    isPlaying(): boolean {
        return this.playing;
    }

    /**
     * This method is used for external observers (like button listeners) to append data to Mus Trials.
     *
     * @param frame the frame to add
     */
    addExternalFrame(frame: Frame): number {
        const currentTimePoint = this.getCurrentTimePoint();
        if (this.recording) {
            if (frame[1] !== 0) {
                throw new Error('Implementation error: timepoint must be set to 0 while adding external data!');
            }
            frame[1] = currentTimePoint;
            this.frames.push(frame);
            return currentTimePoint;
        } else {
            console.error('Mus recording not started. Cannot add external frames.');
            return -1;
        }
    }

    requestScreenshot(triggeredBy: string, timePoint: number, scale: number = this.scaleFactor) {
        Mus.makeScreenshot({
            scale,
        })
            .then((canvas) => {
                this.screenshots.push([
                    canvas.canvasString,
                    timePoint,
                    canvas.size.width,
                    canvas.size.height,
                    scale,
                    triggeredBy
                ]);
            });
    }

    record(onFrame: (() => void) | undefined) {
        if (this.recording) {
            console.error('Recording is already started!');
            return;
        }
        if (this.startedAt === 0) {
            this.startedAt = new Date().getTime(); // keep millisecond unit
        }
        // setup window size data
        this.windowSize = {
            width: window.outerWidth,
            height: window.outerHeight
        };
        this.scrollSize = {
            width: document.querySelector('body').scrollWidth,
            height: document.querySelector('body').scrollHeight
        };
        // initial value
        this.frames.push([
            's',
            0,
            document.scrollingElement.scrollLeft,
            document.scrollingElement.scrollTop,
        ]);
        for (const elem of Array.from<HTMLInputElement>(document.querySelectorAll('textarea, input[type=text], input[type=email], input[type=number], input[type=password], input[type=tel], input[type=search], input[type=url], input[type=search], input[type=week], input[type=month], input[type=datetime-local]'))) {
            this.frames.push([
                'i',
                0,
                getXpathFromElement(elem),
                elem.value,
            ]);
        }
        for (const elem of Array.from<HTMLSelectElement>(document.querySelectorAll('select, input[type=checkbox], input[type=radio], input[type=color], input[type=date], input[type=file], input[type=number], input[type=range], input[type=time]'))) {
            this.frames.push([
                'o',
                0,
                getXpathFromElement(elem),
                elem.value,
            ]);
        }
        // Make an initial screenshot
        this.requestScreenshot('start', 0);
        // register callbacks
        // register listeners for existing elements
        window.onmousemove = this.moveListener((data => {
            this.frames.push([
                'm',
                this.getCurrentTimePoint(),
                data.clientX,
                data.clientY,
            ]);
            if (onFrame) {
                onFrame();
            }
        }));
        window.onmousedown = this.clickListener((data => {
            const timepoint = this.getCurrentTimePoint();
            this.frames.push([
                'c',
                timepoint,
                data.clientX,
                data.clientY,
            ]);
            if (onFrame) {
                onFrame();
            }
        }));
        window.onscroll = this.scrollListener((data => {
            const timepoint = this.getCurrentTimePoint();
            this.frames.push([
                's',
                timepoint,
                data.scrollLeft,
                data.scrollTop,
            ]);
            if (onFrame) {
                onFrame();
            }
        }));
        window.onkeypress = this.keyPressListener((data => {
            this.frames.push([
                'i',
                this.getCurrentTimePoint(),
                data.xpath,
                data.key,
            ]);
            if (onFrame) {
                onFrame();
            }
        }));
        // Failsafe: do not allow resizing during recording
        window.onresize = () => {
            console.error('Error: do not resize during recording!');
            alert('Resizing is not allowed during recording. Record will stop and data will be discarded.');
            this.stop();
            this.release();
        };
        // Start
        this.recording = true;
    }

    stop() {
        this.finishedAt = new Date().getTime();
        // restore original event listeners; delete ours
        window.onmousemove = this.onmousemove;
        window.onmousedown = this.onmousedown;
        window.onscroll = this.onscroll;
        window.onkeypress = this.onkeypress;
        window.onresize = this.onresize;
        for (const observer of this.observers) {
            observer.disconnect();
        }
        this.observers = [];
        this.timeouts = [];
        this.recording = false;
        this.playing = false;
        this.pos = 0;
        console.log('Recording stopped.');
    }

    pause() {
        if (this.playing) {
            this.pos = this.currPos;
            this.playing = false;
            this.clearTimeouts();
        }
    }

    play(onfinish: (() => void) | undefined) {
        if (this.playing) {
            console.error('Do not start playback twice.');
            return;
        }
        const node = Mus.createCursor();
        let delay = 0;
        for (; this.pos < this.frames.length; this.pos++) {
            delay = this.frames[this.pos][1] as number; // timePoint is always in the 2nd index
            this.timeouts.push(setTimeout(function(pos) {
                // Plays specific timeout
                this.playFrame(this.frames[pos], node);
                this.currPos = pos;

                if (pos === this.frames.length - 1) {
                    node.style.backgroundColor = 'transparent';
                    this.timeouts = [];
                    this.playing = false;
                    this.pos = 0;
                    if (onfinish) {
                        onfinish();
                    }
                }
            }.bind(this), delay, this.pos));
        }
        this.playing = true;
    }

    release() {
        this.stop();
        this.frames = [];
        this.screenshots = [];
        this.startedAt = 0;
        this.finishedAt = 0;
        Mus.destroyCursor();
        Mus.destroyClickSnapshot();
    }

    private playFrame(frame: Frame, node: HTMLDivElement) {
        if (frame[0] === 'm') {
            node.style.left = this.getXCoordinate(frame[2]) + 'px';
            node.style.top = this.getYCoordinate(frame[3]) + 'px';
        } else if (frame[0] === 'c') {
            Mus.createClickSnapshot(frame[3], frame[2]);
        } else if (frame[0] === 's') {
            window.scrollTo(frame[2], frame[3]);
        } else if (frame[0] === 'i') {
            const element = getElementByXpath(frame[2]) as HTMLInputElement;
            element.value = frame[3];
        } else if (frame[0] === 'o') {
            const element = getElementByXpath(frame[2]) as HTMLSelectElement;
            element.value = frame[3];
        } else if (frame[0] === 'b') {
            Mus.createButtonClickSnapshot(frame[3], frame[2]);
        } else {
            console.error('Invalid frame type!');
        }
    }

    private clearTimeouts() {
        for (const i of this.timeouts) {
            clearTimeout(this.timeouts[i]);
        }
        this.timeouts = [];
    }

    private timeElapsed() {
        return this.finishedAt - this.startedAt;
    }

    private moveListener(callback: (data: {
        clientX: number;
        clientY: number;
    }) => void): (e: any) => void {
        return (e) => {
            if (callback) {
                callback({
                    clientX: e.clientX,
                    clientY: e.clientY
                });
            }
        };
    }

    private clickListener(callback: (data: {
        clientX: number;
        clientY: number;
    }) => void): (e: any) => void {
        return (e) => {
            if (callback) {
                callback({
                    clientX: e.clientX,
                    clientY: e.clientY
                });
            }
        };
    }

    private scrollListener(callback: (data: {
        scrollLeft: number;
        scrollTop: number;
    }) => void): (e: any) => void {
        return (e) => {
            if (callback) {
                callback({
                    scrollLeft: document.scrollingElement.scrollLeft,
                    scrollTop: document.scrollingElement.scrollTop
                });
            }
        };
    }

    private keyPressListener(callback: (data: {
        xpath: string;
        key: string;
    }) => void): (e: any) => void {
        return (e) => {
            if (callback) {
                callback({
                    xpath: getXpathFromElement(e.target),
                    key: e.target.value
                });
            }
        };
    }

    private optionChangeListener(callback: (data: {
        xpath: string;
        value: string;
    }) => void): (e: any) => void {
        return (e) => {
            if (callback) {
                callback({
                    xpath: getXpathFromElement(e.target),
                    value: e.target.value
                });
            }
        };
    }

    private getCurrentTimePoint(): number {
        return new Date().getTime() - this.startedAt;
    }

    /**
     * Calculates current X coordinate of mouse based on window dimensions provided
     *
     * @param x integer the x position
     * @return integer calculated x position
     */
    private getXCoordinate(x: number): number {
        if (window.outerWidth > this.windowSize.width) {
            return Math.trunc(this.windowSize.width * x / window.outerWidth);
        }

        return Math.trunc(window.outerWidth * x / this.windowSize.width);
    }

    /**
     * Calculates current Y coordinate of mouse based on window dimensions provided
     *
     * @param y integer the y position
     * @return integer calculated y position
     */
    private getYCoordinate(y: number): number {
        if (window.outerHeight > this.windowSize.height) {
            return Math.trunc(this.windowSize.height * y / window.outerHeight);
        }

        return Math.trunc(window.outerHeight * y / this.windowSize.height);
    }

}
