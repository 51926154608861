<mat-toolbar class="main_toolbar" color="primary">
  <span class="menu-item">
      <a *ngIf="userService.currentUserValue" routerLink="home">
        <img src="assets/MategramWeiß.png" width="50px"/>
      </a>
  </span>
    <span *ngIf="userService.currentUserValue" class="menu-item">
    <a routerLink="home"> {{ 'APP.title' | translate }}
    </a>
    </span>
    <span *ngIf="userService.currentUserValue" class="menu-item">
    <a routerLink="offers"> {{ 'APP.offers' | translate }}
    </a>
    </span>
    <span *ngIf="userService.currentUserValue" class="menu-item">
    <a routerLink="search"> {{ 'APP.search' | translate }}
    </a>
    </span>
    <span *ngIf="userService.currentUserValue" class="menu-item">
    <a routerLink="hashtagSearch"> {{ 'APP.hashtagSearch' | translate }}
    </a>
    </span>
    <span *ngIf="userService.currentUserValue" class="menu-item">
    <a routerLink="upload"> {{ 'APP.upload' | translate }}
    </a>
    </span>

    <span *ngIf="userService.currentUserValue" class="menu-item">
    <a [matBadge]="alertService.getMessagesCount()> 0
      ? alertService.getMessagesCount()
      : null
      "
       matBadgeColor="accent"
       matBadgePosition="before"
       routerLink="profile"
    >
      {{ 'APP.profile' | translate }}
    </a>
  </span>
    <span *ngIf="userService.currentUserValue" class="menu-item"><a
            routerLink="witness"> {{ 'APP.voteWitnesses' | translate }} </a>
  </span>
    <span class="fill-remaining-space"></span>
    <span class="menu-item">
    <img (click)="useLanguage('de')"
         src="assets/germany-flag-xs.png"
         style="width: 32px"/>
  </span>
    <span class="menu-item">
    <img (click)="useLanguage('en')"
         src="assets/united-states-of-america-flag-xs.png"
         style="width: 32px"/>
  </span>
    <span class="menu-item"><button
            (click)="logout()"
            *ngIf="userService.currentUserValue"
            mat-button>
      {{ 'APP.logout' | translate }}
    </button>
  </span>
</mat-toolbar>

<router-outlet></router-outlet>
