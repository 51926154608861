/*
Dialog Component that displays the Users public Key on Profile Page
*/

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ShowPublicKeyDialogData {
  publicKey: string;
}

@Component({
    selector: 'app-dialog-show-public-key',
    templateUrl: 'show-public-key.dialog.html'
})
export class ShowPublicKeyDialogComponent {
    constructor(
    public dialogRef: MatDialogRef<ShowPublicKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShowPublicKeyDialogData
    ) {}

  onCloseClick = () => this.dialogRef.close();
}
