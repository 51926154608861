<div fxLayout="row" fxLayoutGap="20px">
    <div class="offer-create container" fxFlex="50%">
        <h2>Create New Offer</h2>
        <form [formGroup]="offerCreateFormGroup">
            <div class="form-group">
                <mat-form-field id="title" class="form-field">
                    <mat-label>Offer Title</mat-label>
                    <input matInput formControlName="title"/>
                    <mat-error *ngIf="false">
                        Title cannot be empty!
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="corporate_user" class="form-field">
                    <mat-label>Corporate User</mat-label>
                    <mat-select formControlName="corporateUser">
                        <mat-option *ngFor="let user of corporateUserList" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="false">
                        A corporate user must be given!
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="sensitivity_level" class="form-field">
                    <mat-label>Sensitivity Level</mat-label>
                    <mat-select formControlName="sensitivityLevel">
                        <mat-option *ngFor="let item of offerSensitivityList" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="false">
                        Sensitivity level cannot be empty!
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="offer_category" class="form-field">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category">
                        <mat-option *ngFor="let item of offerCategoryList" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="false">
                        Offer category cannot be empty!
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="text" class="form-field">
                    <mat-label>Text</mat-label>
                    <textarea matInput
                              formControlName="text"
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="3"
                              cdkAutosizeMaxRows="10"></textarea>
                    <mat-error *ngIf="false">
                        Offer text cannot be empty!
                    </mat-error>
                </mat-form-field>
            </div>
            <app-multiple-image-select (imageSelected)="onImageSelected($event)"></app-multiple-image-select>
            <div class="form-group">
                <mat-form-field id="hashtags" class="form-field">
                    <mat-label>Hashtags (separated by space)</mat-label>
                    <input matInput formControlName="hashtags"/>
                    <mat-error *ngIf="false">
                        Invalid hashtag format!
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="corporate_tags" class="form-field">
                    <mat-label>Corporate Tags (separated by space)</mat-label>
                    <input matInput formControlName="corporateTags"/>
                    <mat-error *ngIf="false">
                        Invalid tag format!
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="mgc_value" class="form-field">
                    <mat-label>Value</mat-label>
                    <input matInput formControlName="mgcValue"/>
                    <mat-error *ngIf="false">
                        Value must be a number greater than 0!
                    </mat-error>
                </mat-form-field>
            </div>
            <button
                    mat-raised-button
                    class="create-button"
                    color="primary"
                    (click)="onOfferCreate()"
                    [disabled]="!isLoaded || !!hasFormErrors"
            >
                <mat-icon>add</mat-icon>
                Create
            </button>
            <button
                    mat-raised-button
                    class="create-button"
                    color="primary"
                    (click)="jsonOfferInput.click()"
                    [disabled]="!isLoaded"
            >
                Batch Import JSON Offers
            </button>
        </form>
        <input
                #jsonOfferInput
                type="file"
                id="json-offer-input"
                name="jsonOfferInput"
                accept=".json"
                (change)="onFileImported($event)"
                style="display: none"
        />
    </div>
</div>
