import {Component, OnInit} from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {Post} from '../../models/post.model';
import {User, UserService} from '../../authentication/services';
import {PostService} from '../../services/postAction.service';
import {Router} from '@angular/router';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    /* Declaration of variables */

  posts: Post[] = [];
  error: string;
  public currentUser: User;

  constructor(
    private uploadService: UploadService,
    private router: Router,
    userService: UserService,
    private postService: PostService
  ) {
      this.currentUser = userService.currentUserValue;
  }

  ngOnInit() {
      this.getData();
  }

  // Gets triggered whenever someone did an action on a post
  // TODO: This doesn't behave well when deleting a post.
  onPostUpdated = () => {
      this.getData();
  };

  // get data from services
  getData() {
      console.log(this.currentUser);
      this.postService
          .getAllPosts(this.currentUser.username)
          .subscribe(
              data => {
                  this.posts = data;
                  console.log(`posts count: ${this.posts.length}`);
              },
              error => (this.error = error.statusText)
          );

  }

}
