import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from './authentication/services';
import {AlertService} from './services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {ActivityService} from './services/activity.service';
import {ActivityType} from './models/activity.model';
import {ExperimentHintComponent} from './shared/experiment-hint/experiment-hint.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mategram';

  constructor(
    public alertService: AlertService,
    public userService: UserService,
    private activityService: ActivityService,
    private router: Router,
    public translate: TranslateService,
    private dialog: MatDialog
  ) {

      // ngx-translate: this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang('en');

      const browserLang = translate.getBrowserLang();
      console.log(`browser language = ${translate.getBrowserLang()}`);

      // ngx-translate: use browser language, if the lang isn't available, it will use the current loader to get them
      translate.use(browserLang);

      // record route switch
      router.events.pipe(
          filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
          if ((event.url.match(/[\/]/g) || []).length === 1) { // contains exactly 1 "/" => top-level tab switch
              console.log('Tab switch');
              this.activityService.recordActivity(ActivityType.CLICK_TAB, [undefined, event.url]);
          }
      });
  }

  logout() {
      this.userService.logout();
      this.router.navigate(['login']);
  }

  /*
    @author Christina Speck
  */
  useLanguage(newLanguage: string) {
      const msg = `use language ${newLanguage}`;
      console.log(msg);
      this.translate.use(newLanguage);
      this.translate.get('APP.useLanguage', { language: newLanguage }).subscribe((translated) => {
          console.log('Language switch: ' + translated);
      });
  }

}
