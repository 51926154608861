/*
Service verifies that the username, belonging to a selected post
is in fact a legitimate username.
Called in userProfiles component.
*/

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidateProfilesService {
    constructor() {}

    checkUsername(data: string) {
        const pattern = /^[a-zA-Z0-9]{3,25}$/i;
        return pattern.test(data);
    }
}
