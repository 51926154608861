import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-experiment-hint',
    templateUrl: './experiment-hint.component.html',
    styleUrls: ['./experiment-hint.component.css']
})
export class ExperimentHintComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ExperimentHintComponent>,
        @Inject(MAT_DIALOG_DATA) public experimentHint: string
    ) {
    }

    ngOnInit(): void {
    }

}
