// Basic Dialog using angular materia, additional key generation
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../services';

export interface DialogData {
  privateKey: string;
}

@Component({
    selector: 'app-dialog-key-verification',
    templateUrl: 'key-verification.dialog.html',
    styleUrls: ['./key-verification.dialog.css']
})
export class KeyVerificationDialogComponent {
    constructor(
    public dialogRef: MatDialogRef<KeyVerificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService
    ) {}

  onVerifyClick = async () => {
      try {
          const publicKey = await window.crypto.subtle.importKey(
              'spki',
              this.str2ab(
                  window.atob(this.userService.currentUserValue.publicKey)
              ),
              {
                  name: 'RSA-PSS',
                  hash: 'SHA-256'
              },
              true,
              ['verify']
          );
          const privateKey = await window.crypto.subtle.importKey(
              'pkcs8',
              this.str2ab(window.atob(this.data.privateKey)),
              {
                  name: 'RSA-PSS',
                  hash: 'SHA-256'
              },
              true,
              ['sign']
          );
          const signature = await window.crypto.subtle.sign(
              {
                  name: 'RSA-PSS',
                  saltLength: 32
              },
              privateKey,
              new TextEncoder().encode('42')
          );
          window.crypto.subtle
              .verify(
                  {
                      name: 'RSA-PSS',
                      saltLength: 32
                  },
                  publicKey,
                  signature,
                  new TextEncoder().encode('42')
              )
              .then(
                  response => {
                      this.dialogRef.close(response);
                  },
                  err => this.dialogRef.close()
              );
      } catch (e) {
          console.log(e);
          this.dialogRef.close();
      }
  };

  onNoClick(): void {
      this.dialogRef.close();
  }

  str2ab = str => {
      const buf = new ArrayBuffer(str.length);
      const bufView = new Uint8Array(buf);
      for (let i = 0, strLen = str.length; i < strLen; i++) {
          bufView[i] = str.charCodeAt(i);
      }
      return buf;
  };
}
