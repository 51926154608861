<mat-card class="profile_card">
    <mat-card-header>
        <mat-card-title>
            {{ inputUser.firstname }} {{ inputUser.lastname }}
        </mat-card-title>
        <mat-card-subtitle>{{ inputUser.username }}</mat-card-subtitle>
        <div mat-card-avatar class="profile_avatar">
            <mat-icon>face</mat-icon>
        </div>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
        <div class="mat-body"></div>
        <div class="profile_additional_information_entry">
            <p id="profile_voting_power_text">
                {{ 'HOME.votingPower' | translate }}
            </p>
            <p id="profile_voting_power_value">{{ inputUser.votingPower }}%</p>
            <mat-progress-bar
                    class="profile_voting_power"
                    mode="determinate"
                    [value]="inputUser.votingPower"
            ></mat-progress-bar>
            <mat-divider></mat-divider>
        </div>
        <div class="profile_additional_information_entry">
            <p id="profile_balance_text">{{ 'HOME.balance' | translate }}</p>
            <p id="profile_balance_value">
                {{ inputUser.balance && inputUser.balance }} MC
            </p>
        </div>
    </mat-card-content>
</mat-card>