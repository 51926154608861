/* Component handeling Inputs, creating a post and using upload service for distribution to other pages */
import {Component, OnInit} from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {ActivityService} from '../../services/activity.service';
import {Post} from '../../models/post.model';
import {Activity} from '../../models/activity.model';
import {UserService, User} from '../../authentication/services';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyDialogComponent} from './privacyDialog/privacyDialog.dialog';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  selected = 'everybody';
  imageUrl = '/assets/defaultImg.png';
  image: any;
  fileToUpload: File = null;
  public cardDescription: string;
  public cardHashtags: string[];
  public cardLocation: string;
  public newPost: Post;
  public newUploadActivity: Activity;
  public euroCounter: 0;
  public upvotes: [];
  public downvotes: 0;
  public isOwnPost: true;
  public postVisibility: '';
  private currentUser: User = null;

  constructor(
    private uploadService: UploadService,
    private activityService: ActivityService,
    private router: Router,
    private alertService: AlertService,
    userService: UserService,
    public dialog: MatDialog
  ) {
      this.currentUser = userService.currentUserValue;
  }

  // method dealing with picture Uploads, image preview
  handleFileInput(file: FileList) {
      console.log('handlefileinput');
      this.fileToUpload = file.item(0);
      this.imageUrl = this.fileToUpload.name;
      console.log(`handlefileinput ${this.imageUrl}`);
      const reader = new FileReader();
      reader.onload = (event: any) => {
          this.image = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
  }

  ngOnInit() {
  }

  /* Updating saved location, description, hashtag */
  updateLocation(location: string) {
      this.cardLocation = location;
  }

  updateDescription(description: string) {
      this.cardDescription = description;
  }

  updateHashtag(hashtag: string) {
      this.cardHashtags = hashtag.split(' ');
  }

  /* Pop-up Dialog when visibility is changed */
  onPrivacySelect(): void {
      const dialogRef = this.dialog.open(PrivacyDialogComponent, {
          width: '500px',
          data: {
              message:
          'Changing the visibility will only change it in the feed. Your post is still accessible over the blockchain.'
          }
      });

      dialogRef.afterClosed().subscribe(() => {
      });
  }

  /* Creating  new Post with data */
  onUpload() {
      console.log('upload key confirmed');
      this.newPost = {
          id: -1,
          username: this.currentUser.username,
          description: this.cardDescription,
          name: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
          location: this.cardLocation,
          hashtags: this.cardHashtags,
          profileIcon: '',
          imageUrl: this.imageUrl,
          image: this.image,
          euroCounter: 0,
          votes: [],
          postVisibility: this.selected,
          deleted: false,
          demonetized: false,
          createdAt: null
      };
      /* sending created Post to upload service */
      this.uploadService.addPost(this.newPost)
          .subscribe(res => {
              this.alertService.notify('Your post was uploaded successfully!');
              this.router.navigate(['home']).then(() => {
                  window.location.reload();
              });
          });
  }

}
