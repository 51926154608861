import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material-module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ChartsModule} from 'ng2-charts';

// ngx-translate
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {HomeComponent} from './tabs/home/home.component';
import {SearchComponent} from './tabs/search/search.component';
import {UploadComponent} from './tabs/upload/upload.component';
import {WitnessComponent} from './tabs/witness/witness.component';
import {ProfileComponent} from './tabs/profile/profile.component';
import {LoginComponent} from './authentication/login';
import {RegisterComponent} from './authentication/register';
import {PostComponent} from './shared/post/post.component';
import {AuthInterceptor} from './authentication/helpers';
import {Routing} from './routing';
import {DeletePostDialogComponent} from './shared/delete-post/delete-post.dialog';
import {UserProfilesComponent} from './authentication/userProfiles/userProfiles.component';
import {KeySetupDialogComponent} from './authentication/key-setup/key-setup.dialog';
import {KeyVerificationDialogComponent} from './authentication/shared/key-verification/key-verification.dialog';
import {ShowPublicKeyDialogComponent} from './tabs/profile/public-key/show-public-key.dialog';
import {PrivacyDialogComponent} from './tabs/upload/privacyDialog/privacyDialog.dialog';
import {HashtagSearchComponent} from './tabs/hashtag-search/hashtag-search.component';
import {HashtagButtonComponent} from './shared/hashtag-button/hashtag-button.component';
import {HashtagComponent} from './shared/hashtag/hashtag.component';
import {OnboardingComponent} from './authentication/onboarding/onboarding.component';
import {SpecialOfferComponent} from './shared/specialoffer/specialoffer.component';
import {SpecialOfferHomeComponent} from './tabs/offerhome/offerhome.component';
import {ActivityComponent} from './shared/activity/activity.component';
import {ExchangeRateComponent} from './shared/exchange-rate/exchange-rate.component';
import {ProfileCardComponent} from './shared/profile-card/profile-card.component';
import {OfferAcceptDialog} from './shared/offer-accept-dialog/offer-accept-dialog.component';
import {ExperimentHintComponent} from './shared/experiment-hint/experiment-hint.component';
import {ExperimentControlComponent} from './shared/experiment-control/experiment-control.component';
import {TrialOverviewComponent} from './observer_uis/trial-overview/trial-overview.component';
import {OfferCreateComponent} from './observer_uis/offer-create/offer-create.component';
import {MultipleImageSelectComponent} from './shared/multiple-image-select/multiple-image-select.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrialDetailComponent } from './observer_uis/trial-detail/trial-detail.component';

// ngx-translate AoT requires an exported function for factories
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions,@typescript-eslint/naming-convention
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SearchComponent,
        UploadComponent,
        WitnessComponent,
        ProfileComponent,
        LoginComponent,
        RegisterComponent,
        PostComponent,
        HashtagSearchComponent,
        UserProfilesComponent,
        DeletePostDialogComponent,
        DeletePostDialogComponent,
        PrivacyDialogComponent,
        KeySetupDialogComponent,
        KeyVerificationDialogComponent,
        ShowPublicKeyDialogComponent,
        HashtagButtonComponent,
        HashtagComponent,
        OnboardingComponent,
        SpecialOfferComponent,
        SpecialOfferHomeComponent,
        ActivityComponent,
        ExchangeRateComponent,
        ProfileCardComponent,
        OfferAcceptDialog,
        ExperimentHintComponent,
        ExperimentControlComponent,
        TrialOverviewComponent,
        OfferCreateComponent,
        MultipleImageSelectComponent,
        TrialDetailComponent
    ],
    entryComponents: [
        DeletePostDialogComponent,
        PrivacyDialogComponent,
        KeySetupDialogComponent,
        KeyVerificationDialogComponent,
        ShowPublicKeyDialogComponent
    ],
    imports: [
        MaterialModule,
        HttpClientModule,
        Routing, //Routing here!
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ChartsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
