import {Component, OnInit} from '@angular/core';
import {TrialService} from '../../services/trial.service';
import {AlertService} from '../../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {ExperimentHintComponent} from '../experiment-hint/experiment-hint.component';
import html2canvas from 'html2canvas';

@Component({
    selector: 'app-experiment-control',
    templateUrl: './experiment-control.component.html',
    styleUrls: ['./experiment-control.component.css']
})
export class ExperimentControlComponent implements OnInit {

    constructor(
        private trialService: TrialService,
        public translate: TranslateService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        //const isOffersExperimentHintDisplayed = localStorage.getItem('isOffersExperimentHintDisplayed');
        //if (!isOffersExperimentHintDisplayed)
        {
            /*
                A smart workaround here: do NOT make the experiment hint optional.
                Dialogs in Angular uses the cdk-overlay-container class to present.
                However, this element is only lazily added to the DOM, that is, only when the first dialog is opened.
                Every dialog adds new controls (like buttons, textareas) we would like to tract.
                In the Mus.js library, we uses the MutationObserver to track DOM updates and add our own eventListeners.
                If the cdk-overlay-container do not exist, the observer could now locate the root of all DOM changes.
             */
            this.translate.get('EXPERIMENT_HINT.offer_hint')
                .subscribe((hintText: string) => {
                    console.log('login hint: ' + hintText);
                    this.dialog.open(ExperimentHintComponent, {
                        data: hintText
                    });
                    localStorage.setItem('isOffersExperimentHintDisplayed', 'true');
                });
        }
    }
    startRecording() {
        this.trialService.startRecording();
    }
    stopRecording() {
        this.trialService.stopRecording();
    }
    playRecording() {
        this.trialService.playRecording();
    }
    outputRecordingToConsole() {
        this.trialService.outputRecordingToConsole();
    }
    sendToBackend() {
        this.trialService.sendToBackend()
            .subscribe(data => {
                console.log(`Trial id ${data} logged to backend.`);
                this.trialService.clearRecording();
                console.log('Current trial erased.');
            });
    }
    showLoadDialog() {
        const uuid = prompt('Input the uuid of the trial you want to reload & replay:');
        if (uuid) {
            this.trialService.getSingleTrialData(uuid)
                .subscribe(data => {
                    this.trialService.setRecording(data);
                });
        }
    }
    resetAcceptedOffers() {
        this.trialService.resetAcceptedOffers();
    }
    screenshot() {
        const screenshotTarget = document.body;

        html2canvas(screenshotTarget, {
            scale: 1
        }).then((canvas) => {
            const base64image = canvas.toDataURL('image/png');
            const x = window.open();
            x.document.open();
            x.document.write(`<html><body><img src="${base64image}"></img></body></html>`);
            x.document.close();
        });
    }
}
