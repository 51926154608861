<div mat-dialog-content>
  <p class="mat-h4" i18n>
    {{ 'KEY.title' | translate }}
  </p>
  <p class="mat-h4" i18n>
    <mat-icon id="warning" color="primary">warning</mat-icon> {{ 'KEY.text' | translate }}
  </p>
  <mat-divider></mat-divider>
  <p id="privateKey" class="mat-body">{{ data.privateKey }}</p>
  <mat-divider></mat-divider>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" i18n>{{ 'KEY.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial i18n>
    {{ 'KEY.confirmed' | translate }}
  </button>
</div>
