<!-- Dialog Component that displays the Users public Key on the Profile Page-->

<div mat-dialog-content>
  <p class="mat-h4" i18n>
    {{ 'SHOWKEY.publicKey' | translate }}
  </p>
  <p class="mat-body" styles="white-space: nowrap">{{ data.publicKey }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCloseClick()" i18n>{{ 'SHOWKEY.close' | translate }}</button>
</div>
