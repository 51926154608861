import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SpecialOffer} from '../models/specialoffer.model';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OfferService {
    constructor(private http: HttpClient) {

    }

    getAllOffers(): Observable<SpecialOffer[]> {
        return this.http.get<SpecialOffer[]>(`${environment.apiUrl}/offers/all`);
    }

    getAllCategories(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.apiUrl}/offer_category/all`);
    }

    getAllSensitivityLevels(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.apiUrl}/offer_sensitivity/all`);
    }

    getAcceptableOffers(): Observable<SpecialOffer[]> {
        return this.http.get<SpecialOffer[]>(`${environment.apiUrl}/offers/acceptable`);
    }

    async rejectOffer(offer: SpecialOffer): Promise<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrl}/offer/${offer.id}/reject`, null, {
            observe: 'response'
        }).toPromise();
    }

    async acceptOffer(offer: SpecialOffer): Promise<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrl}/offer/${offer.id}/accept`, null, {
            observe: 'response'
        }).toPromise();
    }

    // simpler to chain than rxjs constructors
    async relateOfferToPost(offer: SpecialOffer, postId: number): Promise<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrl}/offer/${offer.id}/post/${postId}`, null, {
            observe: 'response'
        }).toPromise();
    }

    async createOffer(offer: SpecialOffer): Promise<HttpResponse<any>> {
        return this.http.post(`${environment.apiUrl}/offer/create`, offer, {
            observe: 'response'
        }).toPromise();
    }
}
