import {Component, OnInit} from '@angular/core';
import {SpecialOffer} from '../../models/specialoffer.model';
import {User} from '../../models/user.model';
import {UserService} from '../../authentication/services';
import {OfferService} from '../../services/offer.service';

@Component({
    selector: 'app-offerhome',
    templateUrl: './offerhome.component.html',
    styleUrls: ['./offerhome.component.css']
})
export class SpecialOfferHomeComponent implements OnInit {

    public specialOffers: SpecialOffer[];

    constructor(
        public userService: UserService,
        private offerService: OfferService
    ) {
    }

    ngOnInit(): void {
        this.updateOffers();
    }

    updateOffers() {
        this.offerService.getAcceptableOffers()
            .subscribe(data => {
                this.specialOffers = data;
            });
    }

    onOfferUpdated() {
        this.updateOffers();
    }
}
