<div fxLayout="row" fxLayoutGap="20px">
  <div class="profile" fxFlex="20%">
    <app-profile-card [inputUser]="currentUser"></app-profile-card>
    <div class="matecoin_card">
      <app-exchange-rate></app-exchange-rate>
    </div>
  </div>
  <div class="post_stream" fxFlex="60%">
    <mat-card>
      <mat-card-header>
        <mat-card-title i18n>{{ 'HOME.newPosts' | translate }}</mat-card-title>
      </mat-card-header>
    </mat-card>
    <app-post
      *ngFor="let item of posts; let i = index"
      [post]="posts[i]"
      [currentUser]="currentUser.username"
      (postUpdated)="onPostUpdated()"
    >
    </app-post>
  </div>

  <div class="activity_component" fxFlex="20%" fxLayout="column">
    <app-activity [user]="this.currentUser"></app-activity>
  </div>
</div>
