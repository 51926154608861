<div fxLayout="column" fxFlex="80%" fxLayoutGap="20px" class="trial-detail container">
    <h3>Current Screen: {{ screensToDisplay[currentObserverFrameIndex] }}</h3>
    <div class="p5-canvas-container" #p5Container></div>
    <div fxLayout="row">
        <button mat-raised-button color="primary" (click)="previousObserverFrame()" [disabled]="currentObserverFrameIndex === 0">
            Previous
        </button>
        <button mat-raised-button color="primary" (click)="nextObserverFrame()" [disabled]="currentObserverFrameIndex === screensToDisplay.length - 1">
            Next
        </button>
    </div>
</div>
<div fxLayout="column" fxFlex="20%" class="trial-detail container">
    <h4>Options:</h4>
    <section>
        <ul>
            <label>Colors: </label>
            <li *ngFor="let trial of selectedTrials">
                {{ trial.substring(0, 7) }}
                <div class="color-indicator" [ngStyle]="{'background-color': renderColor[trial]}">
                </div>
            </li>
        </ul>
    </section>
    <section>
        <ul>
            <label>Show Trials:</label>
            <li *ngFor="let trial of trialUUID">
                <mat-checkbox class="trial-checkbox"
                              [checked]="selectedTrials.has(trial)"
                              (change)="onSelectedTrialChange($event.checked, trial)">
                    {{ trial.substring(0, 7) }}
                </mat-checkbox>
            </li>
        </ul>
    </section>
    <section>
        <label>Reference Screenshot From:</label>
        <mat-select [(value)]="referenceScreenshotFrom"
                    (selectionChange)="drawCurrentObserverFrame()">
            <mat-option *ngFor="let trial of selectedTrials"
                        [value]="trial">
                {{ trial.substring(0, 7 )}}
            </mat-option>
        </mat-select>
    </section>
    <section>
        <label>Draw timestamp value at every X frame:</label>
        <mat-slider
            min="1"
            max="50"
            step="1"
            thumbLabel
            tickInterval="auto"
            [(ngModel)]="everyXFrame"
            (valueChange)="drawCurrentObserverFrame()">
        </mat-slider>
    </section>
</div>
