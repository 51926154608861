import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {HashtagService} from '../../services/hashtag.service';

@Component({
    selector: 'app-hashtag-search',
    templateUrl: './hashtag-search.component.html',
    styleUrls: ['./hashtag-search.component.css']
})
export class HashtagSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('term')
  private term: ElementRef;
  hashtags: string[];
  matches: string[];
  inProgress: boolean;
  nothingFound: boolean;

  constructor(
    private hashtagService: HashtagService
  ) {
      this.hashtagService
          .getAllTags()
          .subscribe(data => {
              this.hashtags = data;
              this.matches = data;
          });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
      fromEvent<any>(this.term.nativeElement, 'keyup')
          .pipe(
              map(event => event.target.value),
              debounceTime(500)
          )
          .subscribe(term => {
              if (term) {
                  const searchTerm = `${term}`;
                  this.inProgress = true;
                  window.setTimeout(() => {
                      this.inProgress = false;
                      this.matches = this.hashtags.filter(tag => tag.toUpperCase().includes(searchTerm.toUpperCase()));
                      if (this.matches.length === 0) {
                          this.nothingFound = true;
                      }
                  }, 1000);
              } else {
                  this.nothingFound = false;
                  this.matches = this.hashtags;
              }
          });
  }

  onSearchTermChange() {
      this.nothingFound = false;
  }

  onClick(hashtag: string) {

  }
}
