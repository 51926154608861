// services to get information about userbase
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {User} from '../../models/user.model';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CorporateUser} from '../../models/corporateUser.model';

@Injectable({providedIn: 'root'})
export class UserService {
    public currentUser: User;
    public token: string;
    constructor(
        private http: HttpClient,
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = localStorage.getItem('id_token');
    }

    //TODO: Fix all APIs below

    getAll(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/user/getAll`);
    }

    getUser(username: string): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/user/get/${username}`);
    }

    getAllCorporateUsers(): Observable<Array<CorporateUser>> {
        return this.http.get<CorporateUser[]>(`${environment.apiUrl}/user/getAllCorporate`);
    }

    public get currentUserValue() {
        return this.currentUser;
    }

    public get currentUserToken() {
        return this.token;
    }


    login(username: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}/auth/login`, {
                username,
                password
            }, {observe: 'response'})
            .pipe(
                map(response => {
                    console.log(response);
                    this.token = response.headers.get('Authorization');
                    localStorage.setItem('id_token', this.token);
                    this.currentUser = response.body;
                    localStorage.setItem('currentUser', JSON.stringify(response.body));
                })
            );
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        localStorage.removeItem('id_token');
        this.currentUser = null;
        this.token = '';
    }

    register(user: User, publicKey: string): Observable<any> {
        user.publicKey = publicKey;
        return this.http.post(`${environment.apiUrl}/auth/register`, user, {
            observe: 'response',
        });
    }

    updateCurrentUserInfo(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/user/get/${this.currentUser.username}`)
            .pipe(
                tap(value => {
                    localStorage.setItem('currentUser', JSON.stringify(value));
                    this.currentUser = value;
                })
            );
    }

    // deletion of user by id
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/delete/${id}`);
    }

    reduceVotingPower(user: User) {
        if (user.votingPower > 5) {
            user.votingPower -= 5;
        }
        return this.http
            .post(`${environment.apiUrl}/user/update/votingpower`, user)
            .subscribe(() => {
                console.log('Trying to reduce voting power');
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUser = user;
            });
    }

    onboard(param) {
        return this.http.post(`${environment.apiUrl}/user/onboard`, param, {
            observe: 'response',
        });
    }
}
