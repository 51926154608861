import {Injectable} from '@angular/core';
import {PrivacyType} from '../models/PrivacyType.model';

@Injectable({
    providedIn: 'root'
})
export class PrivacyService {
  public privacySettings: PrivacyType[] = [
      { index: 0, label: 'Everyone' },
      { index: 1, label: 'Only following' },
      { index: 2, label: 'Only myself' },
  ];
}
