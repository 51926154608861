<mat-card>
    <mat-card-header>
        <mat-card-title i18n>
            {{ "HOME.exchangeRate" | translate }}
        </mat-card-title>
        <mat-card-subtitle>1MC = {{ currentMCExchange }} $</mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
        <canvas
                baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                chartType="line"
        >
        </canvas>
    </mat-card-content>
</mat-card>