import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {OfferService} from '../../services/offer.service';
import {CorporateUser} from '../../models/corporateUser.model';
import {UserService} from '../../authentication/services';
import {MultipleImageSelectComponent} from '../../shared/multiple-image-select/multiple-image-select.component';
import {AlertService} from '../../services/alert.service';
import {throwError} from 'rxjs';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Component({
    selector: 'app-offer-create',
    templateUrl: './offer-create.component.html',
    styleUrls: ['./offer-create.component.css']
})
export class OfferCreateComponent implements OnInit {
    @ViewChild(MultipleImageSelectComponent)
    private multipleImageSelectComponent!: MultipleImageSelectComponent;

    public offerCategoryList: string[] | null = null;
    public offerSensitivityList: string[] | null = null;
    public corporateUserList: CorporateUser[] | null = null;
    public offerCreateFormGroup = this.fb.group({
        title: ['', Validators.required],
        corporateUser: [null, Validators.required],
        sensitivityLevel: [null, Validators.required],
        category: [null, Validators.required],
        text: ['', Validators.required],
        hashtags: ['', Validators.required],
        corporateTags: ['', Validators.required],
        mgcValue: ['', Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9]*$') // numeric
        ])]
    });

    private selectedImage: string[] = [];

    get isLoaded(): boolean {
        return !!this.offerCategoryList && !!this.offerSensitivityList && !!this.corporateUserList;
    }
    get hasFormErrors() {
        return (
            Object.keys(this.offerCreateFormGroup.controls)
                .map(key => this.offerCreateFormGroup.get(key).invalid)
                .find(invalid => invalid === true) || this.offerCreateFormGroup.errors
        );
    }

    constructor(
        private fb: FormBuilder,
        private offerService: OfferService,
        private userService: UserService,
        private alertService: AlertService
    ) { }

    ngOnInit(): void {
        this.offerService.getAllCategories()
            .pipe(catchError(this.handleError.bind(this)))
            .subscribe((data) => {
                this.offerCategoryList = data;
            });
        this.offerService.getAllSensitivityLevels()
            .pipe(catchError(this.handleError.bind(this)))
            .subscribe((data) => {
                this.offerSensitivityList = data;
            });
        this.userService.getAllCorporateUsers()
            .pipe(catchError(this.handleError.bind(this)))
            .subscribe((data) => {
                this.corporateUserList = data;
            });
    }

    onImageSelected(event: string[]) {
        if (event.length <= 3){
            this.selectedImage = event;
        } else {
            this.selectedImage = [];
            this.alertService.error('Maximum 3 images allowed for a special offer!');
        }
    }

    onOfferCreate() {
        if (this.selectedImage.length === 0) {
            this.alertService.error('At least 1 image is required for a special offer!');
            return;
        }
        const newOffer = {
            ...this.offerCreateFormGroup.value,
            images: this.selectedImage
        };
        // split hashtags
        newOffer.hashtags = newOffer.hashtags.split(' ');
        newOffer.corporateTags = newOffer.corporateTags.split(' ');
        // placeholder id
        newOffer.id = -1;
        console.log(newOffer);
        this.offerService.createOffer(newOffer)
            .then((response) => {
                if (response.status === 200) {
                    this.alertService.success('Offer creation success!');
                    setTimeout(() => {
                        // document.location.reload();
                    }, 3000);
                } else {
                    this.alertService.error('Offer creation error, see console');
                    console.log(response);
                }
            });
    }

    onFileImported(event: Event) {
        const importFile = (event.target as HTMLInputElement).files[0];
        if (importFile) {
            const reader = new FileReader();
            reader.onload = async (content) => {
                try {
                    const result: Array<any> = JSON.parse(content.target.result as string);
                    if (result.length === 0) {
                        throw new Error('The import JSON file must be an array of offers!');
                    }
                    for (const offer of result) {
                        if (!(
                            offer.category
                            && offer.corporateTags
                            && offer.corporateUser
                            && offer.hashtags
                            && offer.images
                            && offer.mgcValue
                            && offer.sensitivityLevel
                            && offer.text
                            && offer.title
                            && offer.mgcValue > 0
                        )) {
                            throw new Error('Please specify all fields in an offer!');
                        }
                    }
                    const offerCreationPromise: Array<Promise<HttpResponse<any>>> = [];
                    for (const offer of result) {
                        offer.id = -1;
                        if (typeof offer.corporateUser !== 'number') {
                            offer.corporateUserName = offer.corporateUser;
                            delete offer.corporateUser;
                        }
                        console.log('Batch creating offer: ', offer);
                        offerCreationPromise.push(this.offerService.createOffer(offer));
                    }
                    try {
                        await Promise.all(offerCreationPromise);
                        this.alertService.success('Batch offer creation success!');
                    } catch (e) {
                        console.log(e);
                        this.alertService.error('Not all offers were created successfully. See console.');
                    }
                } catch (e) {
                    this.alertService.error(e.message);
                }
            };
            reader.readAsText(importFile);
        }
    }

    private handleError(error: HttpErrorResponse) {
        console.log(error);
        this.alertService.error('An error occurred. See console for debugging.');
        return throwError(error);
    }
}
