/*
Service can receive new witnesses and return all witnesses (witnesses Array)
20 mock witnesses are implemented.
*/

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Witness} from '../models/witness.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class WitnessService {
  public witnessSubject = new Subject<Witness>();
  public witnessObservable: Observable<Witness[]> = new Observable<Witness[]>();
  private witnesses: Witness[] = new Array<Witness>();

  constructor(private http: HttpClient) {
  }

  addWitnesses(newWitness: Witness) {
      this.witnesses.push(newWitness);
      this.witnessSubject.next(newWitness);
  }

  getWitnesses(): Observable<Witness[]> {
      return new Observable<Witness[]>(subscriber => {
          this.witnesses = []; // clear old witnesses before GET
          this.http.get(`${environment.apiUrl}/witness/allwithvote`).subscribe(data => {
              const witnessTemp = new Array<Witness>();
              (data as Array<any>).forEach(i => {
                  witnessTemp.push({
                      rank: 0,
                      username: i.witness.user.username,
                      votes: i.votes,
                  });
              });
              witnessTemp.sort((a, b) => b.votes - a.votes);
              for (let i = 1; i <= witnessTemp.length; i++) {
                  witnessTemp[i - 1].rank = i;
              }
              for (const i of witnessTemp) {
                  this.addWitnesses(i);
              }
              subscriber.next(this.witnesses);
          });
      }
      );
  }
}
