import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HashtagService} from '../../services/hashtag.service';
import {Post} from '../../models/post.model';
import {AlertService} from '../../services/alert.service';

@Component({
    selector: 'app-hashtag',
    templateUrl: './hashtag.component.html',
    styleUrls: ['./hashtag.component.css']
})
export class HashtagComponent implements OnInit {
  currentHashtag: string;
  posts: Post[];
  isLoaded = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private hashtagService: HashtagService,
    private alertService: AlertService
  ) {

  }

  ngOnInit(): void {
      this.activatedRoute.paramMap.subscribe(params => {
          this.currentHashtag = params.get('hashtag');
          this.hashtagService.getPostsByHashtag(this.currentHashtag).subscribe( data => {
              this.posts = data;
              this.isLoaded = true;
          }, error => {
              this.alertService.error('This hashtag does not exist!');
          });
      });
  }

}
