export const consecutiveDifference = (x: Array<number>): Array<number> => x.slice(1).map((n, i) => n - x[i]);
export const sqrtSumOfSquares = (x: Array<number>, y: Array<number>): Array<number> => {
    const result: Array<number> = [];
    if (x.length !== y.length) {
        throw new Error('Expect arrays to have the same length!');
    }
    for (let i = 0;i < x.length; i++) {
        result.push(Math.sqrt(x[i] * x[i] + y[i] * y[i]));
    }
    return result;
};
