<div fxLayout="row" fxLayoutGap="20px">
    <div class="profile" fxFlex="20%">
        <app-profile-card [inputUser]="this.userService.currentUser"></app-profile-card>
        <div class="matecoin_card">
            <app-exchange-rate></app-exchange-rate>
        </div>
    </div>
    <div class="post_stream" fxFlex="60%">
        <mat-card>
            <mat-card-header>
                <mat-card-title i18n>{{ 'SPECIALOFFERHOME.currentOffer' | translate }}</mat-card-title>
            </mat-card-header>
        </mat-card>
        <div class="offer_stream_container">
            <app-specialoffer
                    class="special_offer"
                    *ngFor="let item of specialOffers; let i = index"
                    [offer]="item"
                    (offerUpdated)="onOfferUpdated()"
            >
            </app-specialoffer>
        </div>
    </div>

    <div class="activity_component" fxFlex="20%" fxLayout="column">
        <app-experiment-control></app-experiment-control>
        <app-activity [user]="this.userService.currentUser"></app-activity>
    </div>
</div>
