/* Opens Dialog when privacy setting is chananged */
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

/* Interface for the message displayed when dialog is opened */
export interface DialogData {
    message: string;
  }

@Component({
    selector: 'app-upload',
    templateUrl: 'privacyDialog.dialog.html',
})

export class PrivacyDialogComponent {

    constructor(
      public dialogRef: MatDialogRef<PrivacyDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
