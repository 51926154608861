<!--TODO: fix layout issues -->
<h1 mat-dialog-title>{{ 'SPECIALOFFERACCEPT.title' | translate }}</h1>
<div mat-dialog-content fxLayout="column">
    <div fxLayout="row" class="top_container">
        <div fxFlex="40%" class="image_container">
            <div class="carousel-style">
                <ngb-carousel #carousel (slide)="onSlide($event)">
                    <ng-template ngbSlide id="0">
                        <div class="picsum-img-wrapper">
                            <img mat-card-image [src]="data.images[0]">
                        </div>
                    </ng-template>
                    <ng-template *ngIf="data.images[1]" ngbSlide id="1">
                        <div class="picsum-img-wrapper">
                            <img mat-card-image [src]="data.images[1]">
                        </div>
                    </ng-template>
                    <ng-template *ngIf="data.images[2]" ngbSlide id="2">
                        <div class="picsum-img-wrapper">
                            <img mat-card-image [src]="data.images[2]">
                        </div>
                    </ng-template>
                </ngb-carousel>
                <p>Use the carousel to select the image to be posted.</p>
            </div>
        </div>
        <div fxFlex="60%" class="text_container">
            <div fxLayout="column">
                <mat-form-field>
                    <mat-label>{{ 'SPECIALOFFERACCEPT.text' | translate }}</mat-label>
                    <textarea id="text_input" matInput [defaultValue]="data.text" [(ngModel)]="postText"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="text_container">
        <h4 mat-dialog-title>{{ 'SPECIALOFFERACCEPT.hashtags' | translate}}</h4>
        <mat-form-field>
            <mat-label>{{ 'SPECIALOFFERACCEPT.hashtagHint' | translate }}</mat-label>
            <input matInput [defaultValue]="data.hashtags" [(ngModel)]="hashtags">
        </mat-form-field>
    </div>
    <div class="text_container">
        <h4 mat-dialog-title>{{ 'SPECIALOFFERACCEPT.corporateTags' | translate}}</h4>
        <mat-label>{{ data.corporateTags }}</mat-label>
    </div>
</div>
<mat-dialog-actions class="actions">
    <div fxLayout="row">
        <p class="countdown">
            {{ this.secondsLeft }}
            {{ 'SPECIALOFFERACCEPT.time' | translate }}
        </p>
        <button (click)="onPostClick($event)" mat-raised-button class="sp_button" color="primary">
             {{ 'SPECIALOFFERACCEPT.post' | translate }}
        </button>
    </div>
</mat-dialog-actions>
