<div fxLayout="column" fxLayoutGap="20px" class="trial-info container">
    <h2>Trials Collected</h2>
    <div fxLayout="row">
        <mat-label>Filter by username:</mat-label>
        <input matInput (input)="usernameFilterChanged(($event.target.value))">
    </div>
    <table mat-table [dataSource]="trialInfoList">
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>Select</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="trial-checkbox"
                              (change)="checkboxTrialChanged($event.checked, element.uuid)">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="uuid">
            <th mat-header-cell *matHeaderCellDef>Trial ID</th>
            <td mat-cell *matCellDef="let element">{{ element.uuid }}</td>
        </ng-container>
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>Username</th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">{{ element.localTime }}</td>
        </ng-container>
        <ng-container matColumnDef="time_elapsed">
            <th mat-header-cell *matHeaderCellDef>Time Elapsed</th>
            <td mat-cell *matCellDef="let element">{{ element.timeElapsed }}</td>
        </ng-container>
        <ng-container matColumnDef="window_width">
            <th mat-header-cell *matHeaderCellDef>Window Width</th>
            <td mat-cell *matCellDef="let element">{{ element.windowSize.width }}</td>
        </ng-container>
        <ng-container matColumnDef="window_height">
            <th mat-header-cell *matHeaderCellDef>Window Height</th>
            <td mat-cell *matCellDef="let element">{{ element.windowSize.height }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button color="primary" (click)="getSingleTrialData(element.uuid)">JSON</button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Filters here -->
    <h3>Visualization Options</h3>
    <p>Number of trials to visualize: {{ trialsSelected.size }} (Use checkbox above to modify)</p>
    <p>Screens to render: </p>
    <table class="mat-table">
        <thead>
            <tr class="mat-header-row">
                <th class="mat-header-cell">Screens</th>
                <th class="mat-header-cell"
                    *ngFor="let trial of trialsSelected">
                    {{ trial.uuid.substring(0, 6)}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="mat-row"
                *ngFor="let item of [].constructor(maxScreenLength); let i = index">
                <td class="mat-cell">
                    {{i}}
                    <mat-checkbox class="screen-checkbox"
                                  (change)="screenCheckboxChanged($event.checked, i)">
                    </mat-checkbox>
                </td>
                <td class="mat-cell"
                    *ngFor="let trial of trialsSelected">
                    {{ trial.screenInfo[i] ? trial.screenInfo[i].screenType : ""}}
                </td>
            </tr>
        </tbody>
    </table>
    <button mat-raised-button color="primary"
            [disabled]="trialsSelected.size === 0 || screensSelected.size === 0"
            (click)="startVisualization()">
        Start
    </button>
</div>
