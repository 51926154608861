import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  privateKey: string;
}

@Component({
    selector: 'app-dialog-key-setup',
    templateUrl: 'key-setup.dialog.html',
    styleUrls: ['./key-setup.dialog.css']
})
export class KeySetupDialogComponent {
    constructor(
    public dialogRef: MatDialogRef<KeySetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
