/* functions for adding of posts to an array and upvotes/downvotes on post  */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '../authentication/services';
import {Post} from '../models/post.model';
import {AlertService} from './alert.service';
import {environment} from 'src/environments/environment';
import {ActivityService} from './activity.service';
import {map} from 'rxjs/operators';
import {ActivityType} from '../models/activity.model';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
  error: string;

  /* myPosts: Post[]; */

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private alertService: AlertService,
    private activityService: ActivityService
  ) {
  }

  /* Adds post to the array of posts it is called from*/
  addPost(newPost: Post): Observable<number> {

      return this.http
          .post<number>(`${environment.apiUrl}/posts`, newPost)
          .pipe(map((res: number) => {
              this.activityService.recordActivity(ActivityType.UPLOAD_POST, [
                  res.toString(),
                  newPost.description,
                  newPost.postVisibility,
                  null, // TODO: we currently cannot get the offer id here due to how offers are associated.
              ]);
              return res;
          }));
  }


  /* changes amount of likes +1, disables possability of liking, disliking,  */
  upvotePost(post: Post): boolean {
      const currentUser = this.userService.currentUserValue;
      const upvote = {
          username: currentUser.username,
          upvoted: true
      };

      if (
          post &&
      !post.votes.find(v => v.username === upvote.username && v.upvoted) &&
      currentUser.votingPower >= 5
      ) {
          post.votes.push(upvote);
          this.http
              .put(`${environment.apiUrl}/votes/${post.id}`, upvote)
              .subscribe(() => {
                  this.userService.reduceVotingPower(
                      this.userService.currentUserValue
                  );
              });
          return true;
      } else {
          this.alertService.error('Your voting power is not sufficient!');
          return false;
      }
  }

  /* changes amount of dislikes +1, disables possability of liking, disliking  */
  downvotePost(post: Post): boolean {
      const currentUser = this.userService.currentUserValue;
      const upvote = {
          username: currentUser.username,
          upvoted: false
      };

      if (
          post &&
      !post.votes.find(v => v.username === upvote.username && !v.upvoted) &&
      currentUser.votingPower >= 5
      ) {
          post.votes.push(upvote);
          this.http
              .put(`${environment.apiUrl}/votes/${post.id}`, upvote)
              .subscribe(() => {
                  this.userService.reduceVotingPower(
                      this.userService.currentUserValue
                  );
              });
          return true;
      } else {
          this.alertService.error('Your voting power is not sufficient!');
          return false;
      }
  }
}
