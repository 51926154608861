import {Component, Input, OnInit} from '@angular/core';
import {ActivityService} from '../../services/activity.service';
import {AlertService} from '../../services/alert.service';
import {User} from '../../models/user.model';
import {ActivityView} from '../../models/activityView.model';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  @Input() user: User;
  allActivities: ActivityView[];
  error: string;
  constructor(
    private activityService: ActivityService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
      // this.getAllActivities();
  }

    /*
  getAllActivities() {
    this.activityService
      .getAllActivities()
      .subscribe(
        data => {
          this.allActivities = data;
          console.log(`activities count: ${this.allActivities.length}`);
        },
        error => {
          this.error = error.statusText;
          this.alertService.error(this.error);
        }
      );
  }

   */
}
