import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SpecialOffer} from '../../models/specialoffer.model';
import {NgbCarousel, NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap';
import {TrialService} from '../../services/trial.service';

@Component({
    selector: 'app-offer-accept-dialog',
    templateUrl: './offer-accept-dialog.component.html',
    styleUrls: ['./offer-accept-dialog.component.css']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OfferAcceptDialog implements OnInit {
    @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

    secondsLeft: number;
    postText: string;
    hashtags: string;
    currentCarouselSlide: string;

    constructor(
        private trialService: TrialService,
        public dialogRef: MatDialogRef<OfferAcceptDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SpecialOffer
    ) {
    }

    ngOnInit(): void {
        this.carousel.pause();
        this.secondsLeft = 60;
        this.postText = this.data.text;
        console.log(this.data.hashtags);
        if (this.data.hashtags && this.data.hashtags.length > 0) {
            this.hashtags = this.data.hashtags.reduce((previousValue, currentValue, currentIndex, array) => previousValue + ' ' + currentValue);
        }
        const x = setInterval(() => {
            this.secondsLeft--;
            if (this.secondsLeft < 0) {
                clearInterval(x);
                this.dialogRef.close({
                    success: false,
                });
            }
        }, 1000);
    }

    onSlide(event: NgbSlideEvent) {
        this.currentCarouselSlide = event.current;
        console.log(event.current);
    }

    onPostClick(event: MouseEvent) {
        if (this.secondsLeft < 0) {
            this.dialogRef.close({
                success: false,
            });
            return;
        }
        this.dialogRef.close({
            success: true,
            postText: this.postText,
            hashtags: this.hashtags,
            photo: this.currentCarouselSlide
        });
        this.trialService.recordExternalButtonFrameAndScreenshot('offer_post', this.data.id.toString(), event);
    }
}
