import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TrialService} from '../../services/trial.service';
import {forkJoin} from 'rxjs';
import {Trial, TrialAndScreenshot, TrialScreenshot} from '../../models/trial.model';
import * as p5 from 'p5';
import {ButtonClickFrame, ClickFrame, Frame, MoveFrame} from '../../lib/musjs/mus-ts';
import {consecutiveDifference, sqrtSumOfSquares} from '../../utils';

interface ObserverFrame {
    screenshot: TrialScreenshot;
    frames: Array<Frame>;
    start: number;
    end: number;
}

@Component({
    selector: 'app-trial-detail',
    templateUrl: './trial-detail.component.html',
    styleUrls: ['./trial-detail.component.css']
})
export class TrialDetailComponent implements OnInit, AfterViewInit {
    @ViewChild('p5Container')
    p5Container: ElementRef<HTMLDivElement>;

    myp5: p5;
    trialUUID: Array<string>;
    screensToDisplay: Array<number>;
    isLoading = true;
    relativeWindowSize = 0.8;
    trialAndScreenshots: Array<TrialAndScreenshot> = [];
    observerFrames: Record<string, ObserverFrame[]> = {};
    renderColor: Record<string, p5.Color> = null;
    selectedTrials: Set<string> = new Set();
    referenceScreenshotFrom: string;
    currentObserverFrameIndex = 0;
    currentScaleFactor = 0;
    everyXFrame = 5;

    constructor(
        private route: ActivatedRoute,
        private trialService: TrialService
    ) {
    }

    ngOnInit(): void {
        console.log('ngOnInit called');
        this.trialUUID = this.route.snapshot.queryParamMap.getAll('uuid');
        this.screensToDisplay = this.route.snapshot.queryParamMap.getAll('screen').map(i => parseInt(i, 10));
        this.currentObserverFrameIndex = 0; // minimum screen id to render
        console.log('ngOnInit finished');
    }

    ngAfterViewInit() {
        console.log('ngAfterViewInit called');
        this.trialService.getBatchTrialAndScreenshots(this.trialUUID)
            .subscribe(results => {
                console.log('Batch Trial and screenshot fetched');
                this.trialAndScreenshots = results;
                for (const input of results) {
                    this.observerFrames[input.uuid] = this.frameDecompose(input);
                }
                // Default: select all trials
                this.selectedTrials = new Set(results.map(i => i.uuid));
                this.drawCurrentObserverFrame();
                this.isLoading = false;
            });
        console.log('ngAfterViewInit finished');
    }

    onSelectedTrialChange(isChecked: boolean, uuid: string) {
        if (isChecked) {
            this.selectedTrials.add(uuid);
        } else {
            this.selectedTrials.delete(uuid);
        }
        // redraw
        this.drawCurrentObserverFrame();
    }

    drawCurrentObserverFrame() {
        console.log('drawCurrentObserverFrame called');
        // Default: use screenshot from the first trial
        this.referenceScreenshotFrom = this.selectedTrials.values().next().value;
        // Erase old canvas
        this.p5Container.nativeElement.querySelector('.p5-canvas')?.remove();
        const newp5 = document.createElement('div');
        newp5.classList.add('p5-canvas');
        this.p5Container.nativeElement.appendChild(newp5);
        // draw canvas based on reference frame
        const referenceObserverFrame = this.observerFrames[this.referenceScreenshotFrom][this.screensToDisplay[this.currentObserverFrameIndex]];
        const referenceTrial = this.trialAndScreenshots.find(i => i.uuid === this.referenceScreenshotFrom);
        const targetWidth = window.innerWidth * this.relativeWindowSize;
        this.currentScaleFactor = targetWidth / referenceTrial.trial.windowSize.width * referenceObserverFrame.screenshot.scaleFactor;
        this.myp5 = new p5((sketch: p5) => {
            let p5_background: p5.Image;
            sketch.setup = () => {
                console.log('p5 Canvas setup called');
                p5_background = sketch.loadImage(referenceObserverFrame.screenshot.image);
                sketch.createCanvas(
                    referenceObserverFrame.screenshot.size.width * this.currentScaleFactor,
                    referenceObserverFrame.screenshot.size.height * this.currentScaleFactor
                );
                // generate a random color for every trial
                if (!this.renderColor) {
                    this.randomColorForTrials(sketch, this.trialUUID);
                }
                console.log('p5 Canvas setup done');
            };
            sketch.draw = () => {
                console.log('p5 Canvas draw called');
                sketch.background(p5_background);
                console.log('p5 background drawn');
                for (const [uuid, currentObserverFrames] of Object.entries(this.observerFrames)) {
                    if (this.selectedTrials.has(uuid)) {
                        const currentObserverFrame = currentObserverFrames[this.screensToDisplay[this.currentObserverFrameIndex]];
                        if (currentObserverFrame) {
                            const mouseFrames = currentObserverFrame.frames.filter(
                                f => f[0] === 'm'
                            ) as Array<MoveFrame>;
                            const clickFrames = currentObserverFrame.frames.filter(
                                f => f[0] === 'c'
                            ) as Array<ClickFrame>;
                            this.drawMouseMovementPoints(sketch, this.renderColor[uuid], mouseFrames);
                            this.drawVelocityVectors(sketch, this.renderColor[uuid], mouseFrames);
                            this.drawClickFramesWithAnnotation(sketch, this.renderColor[uuid], clickFrames);
                            this.drawTimePointAnnotation(sketch, this.renderColor[uuid], mouseFrames, this.everyXFrame);
                            if (currentObserverFrame.frames[currentObserverFrame.frames.length - 1][0] === 'b') {
                                this.drawFrameTransitionAnnotation(sketch,
                                    this.renderColor[uuid],
                                    currentObserverFrame.frames[currentObserverFrame.frames.length - 1] as ButtonClickFrame
                                );
                            }
                        }
                    }
                }
                sketch.noLoop();
                console.log('p5 Canvas draw done');
            };
        }, document.querySelector('.p5-canvas'));
        console.log('drawCurrentObserverFrame finished');
    }

    previousObserverFrame() {
        if (this.currentObserverFrameIndex !== 0) {
            this.currentObserverFrameIndex--;
            this.drawCurrentObserverFrame();
        }
    }

    nextObserverFrame() {
        if (this.currentObserverFrameIndex !== this.screensToDisplay.length - 1) {
            this.currentObserverFrameIndex++;
            this.drawCurrentObserverFrame();
        }
    }

    drawMouseMovementPoints(sketch: p5, color: p5.Color, mouseFrames: Array<MoveFrame>) {
        sketch.push();
        sketch.stroke(color);
        sketch.strokeWeight(5);
        for (const frame of mouseFrames) {
            if (frame[0] === 'm') {
                sketch.point(frame[2] * this.currentScaleFactor, frame[3] * this.currentScaleFactor);
            }
        }
        sketch.pop();
    }

    drawVelocityVectors(sketch: p5, color: p5.Color, mouseFrames: Array<MoveFrame>) {
        sketch.push();
        // calculate speed
        const x = mouseFrames.map(i => parseInt(i[2] as unknown as string, 10));
        const y = mouseFrames.map(i => parseInt(i[3] as unknown as string, 10));
        const t = mouseFrames.map(i => parseInt(i[1] as unknown as string, 10));
        const dx = consecutiveDifference(x);
        const dy = consecutiveDifference(y);
        const dt = consecutiveDifference(t);
        const vx = dx.map((n, i) => n / dt[i]);
        const vy = dy.map((n, i) => n / dt[i]);
        const v = sqrtSumOfSquares(vx, vy);
        for (let i = 0; i < mouseFrames.length - 1; i++) {
            const frame = mouseFrames[i];
            const nextFrame = mouseFrames[i + 1];
            // start point
            const v0 = sketch.createVector(x[i] * this.currentScaleFactor, y[i] * this.currentScaleFactor);
            // end point
            const v1 = sketch.createVector(x[i + 1] * this.currentScaleFactor, y[i + 1] * this.currentScaleFactor);
            const strokeWeightRatio = 1 / 2;
            this.drawArrow(sketch, v0, v1, color, v[i] * strokeWeightRatio);
        }
        sketch.pop();
    }

    drawClickFramesWithAnnotation(sketch: p5, color: p5.Color, clickFrames: Array<ClickFrame>) {
        sketch.push();
        sketch.stroke(color);
        sketch.strokeWeight(8);
        for (const frame of clickFrames) {
            if (frame[0] === 'c') {
                sketch.point(frame[2] * this.currentScaleFactor, frame[3] * this.currentScaleFactor);
                sketch.textSize(5);
                sketch.text('click', (frame[2] - 10) * this.currentScaleFactor, (frame[3] - 10) * this.currentScaleFactor);
            }
        }
        sketch.pop();
    }

    drawTimePointAnnotation(sketch: p5, color: p5.Color, frames: Array<Frame>, everyXFrame: number) {
        sketch.push();
        sketch.stroke(color);
        sketch.textSize(10);
        for (const [i, frame] of frames.entries()) {
            if (i % everyXFrame === 0) {
                sketch.text(
                    frame[1],
                    parseInt(frame[2] as string, 10) * this.currentScaleFactor,
                    parseInt(frame[3] as string, 10) * this.currentScaleFactor
                );
            }
        }
        sketch.pop();
    }

    drawFrameTransitionAnnotation(sketch: p5, color: p5.Color, buttonFrame: ButtonClickFrame) {
        sketch.push();
        sketch.stroke(color);
        sketch.strokeWeight(8);
        sketch.textSize(15);
        let description: string;
        if (buttonFrame[4] === 'offer_accept_dialog') {
            description = 'Transition: Offer Dialog';
        } else if (buttonFrame[4] === 'offer_reject') {
            description = 'Transition: Offer Overview';
        } else if (buttonFrame[4] === 'offer_post') {
            description = 'Transition: Offer Overview';
        } else {
            return;
        }
        sketch.text(
            description,
            parseInt(buttonFrame[2] as unknown as string, 10) * this.currentScaleFactor,
            parseInt(buttonFrame[3] as unknown as string, 10) * this.currentScaleFactor
        );
        sketch.pop();
    }

    private drawArrow(sketch: p5, base: p5.Vector, target: p5.Vector, color: p5.Color, strokeWeight: number) {
        const vec = target.sub(base);
        sketch.push();
        sketch.stroke(color);
        sketch.strokeWeight(strokeWeight);
        sketch.fill(color);
        sketch.translate(base.x, base.y);
        sketch.line(0, 0, vec.x, vec.y);
        sketch.rotate(vec.heading());
        const arrowSize = 7;
        sketch.translate(vec.mag() - arrowSize, 0);
        sketch.triangle(0, arrowSize / 2, 0, -arrowSize / 2, arrowSize, 0);
        sketch.pop();
    }

    private frameDecompose(input: TrialAndScreenshot): Array<ObserverFrame> {
        const observerFrames = [];
        // decompose into ObserverFrames
        for (const [index, screenshot] of input.screenshot.entries()) {
            const newObserverFrame: ObserverFrame = {
                screenshot,
                frames: [],
                start: screenshot.timePoint,
                end: index === input.screenshot.length - 1 ? input.trial.timeElapsed : input.screenshot[index + 1].timePoint
            };
            observerFrames.push(newObserverFrame);
        }
        let frameCursor = 0;
        for (const frame of input.trial.frames) {
            if (frame[1] > observerFrames[frameCursor].end) {
                frameCursor++;
            }
            observerFrames[frameCursor].frames.push(frame);
        }
        return observerFrames;
    }

    private randomColorForTrials(sketch: p5, uuids: Array<string>) {
        this.renderColor = {};
        for (const uuid of uuids) {
            const r = sketch.random(0, 255);
            const g = sketch.random(0, 255);
            const b = sketch.random(0, 255);
            this.renderColor[uuid] = sketch.color(r, g, b);
        }
    }
}
