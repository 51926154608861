// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://aifb-cii-vm03.aifb.kit.edu/mategram-api/mategram',
    collectActivity: true, // this is the control switch for the old by-control data collection.
};

