import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Activity, ActivityType} from '../models/activity.model';
import {UserService} from '../authentication/services';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {

  allActivities: Activity[] = [];
  personalActivities: Activity[];


  constructor(
    private userService: UserService,
    private http: HttpClient,
  ) {

  }

  loadActivity() {

  }

  recordLogin() {

  }

  recordRegister() {

  }

  recordShare() {

  }

  /**
   * This method requires "normal" activities that requires user authentication.
   *
   * @param activityType
   * @param params
   */
  recordActivity(activityType: ActivityType, params: string[]) {
      if (environment.collectActivity) {
          if (params.length > 5) {
              throw new Error('Maximum 5 params!');
          }
          const ac: Activity = {
              activityType,
              id: -1,
              user: this.userService.currentUserValue.id,
              param1: params[0],
              param2: params[1],
              param3: params[2],
              param4: params[3],
              param5: params[4],
          };
          this.http.post(`${environment.apiUrl}/activity/record`, ac, {observe: 'response'})
              .subscribe(response => {
                  if (response.status !== 200) {
                      console.log('An error occurred in recording user activity.');
                  }
              });
      }

  }
  getAllActivity(): Observable<Activity[]> {
      return null;
  }

  getPersonalActivity(): Observable<Activity[]> {
      return null;
  }
}

