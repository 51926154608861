<body>
  <div class="container">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="70">
      <!-- Profile Info section -->

      <div class="item_info">
        <mat-card>
          <mat-card-header>
            <div mat-card-avatar class="activity_stream_avatar_info">
              <mat-icon>face</mat-icon>
            </div>
            <mat-card-title id="profile-info-text">
              <div class="mat-h3">{{ currentUser.username }}</div>
              <div class="mat-body">
                {{ currentUser.firstname }} {{ currentUser.lastname }}
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-card-actions align="end">
              <button mat-flat-button (click)="onShowPublicKeyClick()" i18n>
                {{ 'PROFILE.publicKey' | translate}} {{currentUser.publicKey.substring(0, 6) + '...'}}
              </button>
              <button mat-flat-button i18n>
                {{ 'PROFILE.followers' | translate}} {{currentUser.followers}}
              </button>
              <button mat-flat-button i18n>
                {{ 'PROFILE.following' | translate}} {{currentUser.following}}
              </button>
            </mat-card-actions>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Feed section -->

      <div class="item_feed" fxFlex>
        <mat-card>
          <mat-card-header>
            <mat-card-title i18n>{{ 'PROFILE.myPosts' | translate}}</mat-card-title>
          </mat-card-header>
        </mat-card>
        <app-post
          *ngFor="let item of posts; let i = index"
          [post]="posts[i]"
          [currentUser]="currentUser.username"
        ></app-post>
      </div>

      <!-- Wallet section -->
    </div>
    <div fxLayout="column" fxFlex>
      <div class="item_wallet">
        <app-profile-card [inputUser]="currentUser"></app-profile-card>
      </div>

      <!-- Profile Activity section -->

      <div class="item_account">
        <app-activity [user]="currentUser"></app-activity>
      </div>
    </div>
  </div>
</body>
