import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Country, Gender, Language} from '../../models/profileParam.model';
import {ProfileParamService} from '../../services/profileParam.service';
import {PrivacyService} from '../../services/privacy.service';
import {UserService} from '../services';
import {AlertService} from '../../services/alert.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {

  onboardingForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      telephone: [''],
      email: [''],
      birthday: [null],
      country: [null],
      gender: [null],
      language: [null],
      postPrivacy: [0, Validators.required],
      profilePrivacy: [0, Validators.required],
      activityPrivacy: [0, Validators.required],
      searchPrivacy: [0, Validators.required]
  });

  countryList: Country[] = [];
  genderList: Gender[] = [];
  languageList: Language[] = [];

  private isCountryLoaded = false;
  private isGenderLoaded = false;
  private isLanguageLoaded = false;
  get isLoaded(): boolean {
      return (this.isCountryLoaded && this.isGenderLoaded && this.isLanguageLoaded);
  }

  constructor(
    private fb: FormBuilder,
    private profileParamService: ProfileParamService,
    public privacyService: PrivacyService,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
      this.profileParamService.getAllCountry().subscribe(data => {
          this.countryList = data;
          this.isCountryLoaded = true;
      });
      this.profileParamService.getAllGender().subscribe(data => {
          this.genderList = data;
          this.isGenderLoaded = true;
      });
      this.profileParamService.getAllLanguage().subscribe(data => {
          this.languageList = data;
          this.isLanguageLoaded = true;
      });
  }
  get hasFormErrors() {
      return (
          Object.keys(this.onboardingForm.controls)
              .map(key => this.onboardingForm.get(key).invalid)
              .find(invalid => invalid === true) || this.onboardingForm.errors
      );
  }
  onSubmit(): void {
      this.userService.onboard(this.onboardingForm.value)
          .subscribe(response => {
              if (response.status === 200) {
                  this.router.navigate(['/home']);
              } else {
                  this.alertService.error('Something went wrong.');
              }
          });
  }
}
