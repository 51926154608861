import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {forkJoin, Observable, throwError} from 'rxjs';
import {Trial, TrialAndScreenshot, TrialInfo, TrialScreenshot} from '../models/trial.model';
import {AlertService} from './alert.service';
import {catchError, mergeMap, map, merge} from 'rxjs/operators';
import {Mus, MusRectangle, MusTrial} from '../lib/musjs/mus-ts';

@Injectable({
    providedIn: 'root'
})
export class TrialService {
    private mus: Mus;
    private isRecording = false;
    private offerOverviewSize: MusRectangle;
    private offerDialogSize: MusRectangle;

    constructor(
        private http: HttpClient,
        private alertService: AlertService
    ) {
        this.mus = new Mus();
        this.mus.setScaleFactor(1); // enforce 1:1 scaling
    }

    recordExternalButtonFrameAndScreenshot(transition: string, id: string, event: MouseEvent) {
        if (this.isRecording) {
            const timePoint = this.mus.addExternalFrame([
                'b',
                0,
                event.clientX,
                event.clientY,
                transition,
                id
            ]);
            this.mus.requestScreenshot(transition, timePoint);
        } else {
            console.log('Mus not recording. Request for frame logging ignored.');
        }
    }

    startRecording() {
        // query for offer sizes
        const oneOverview = document.querySelector('.special_offer');
        this.offerOverviewSize = {
            height: oneOverview.clientHeight,
            width: oneOverview.clientWidth
        };
        this.offerDialogSize = null;
        this.isRecording = true;
        this.mus.record(null);
    }

    stopRecording() {
        this.isRecording = false;
        this.mus.stop();
    }

    playRecording() {
        this.mus.play(null);
    }

    clearRecording() {
        this.mus.release();
    }

    setRecording(data: MusTrial) {
        this.clearRecording();
        this.mus.setData(data);
    }

    outputRecordingToConsole() {
        console.log(this.mus.getData());
    }

    sendToBackend(includeScreenshots: boolean = true): Observable<string> {
        console.log('Starting to send data to backend...');
        const musData = this.mus.getData();
        const data: Trial = {
            ...musData,
            offerOverviewSize: this.offerOverviewSize,
            offerDialogSize: this.offerDialogSize
        };
        if (!includeScreenshots) {
            delete data.screenshots;
        }
        return this.http
            .post<string>(`${environment.apiUrl}/trial/save`, data)
            .pipe(
                catchError(this.handleError)
            );
    }

    getTrialInfoList(): Observable<Array<TrialInfo>> {
        return this.http
            .get<Array<TrialInfo>>(`${environment.apiUrl}/trial/info`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getSingleTrialData(uuid: string): Observable<Trial> {
        return this.http
            .get<Trial>(`${environment.apiUrl}/trial/get/${uuid}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getSingleTrialScreenshots(uuid: string): Observable<Array<TrialScreenshot>> {
        return this.http
            .get<Array<TrialScreenshot>>(`${environment.apiUrl}/screenshot/get/${uuid}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getBatchTrialAndScreenshots(uuids: Array<string>): Observable<Array<TrialAndScreenshot>> {
        const result: Array<Observable<TrialAndScreenshot>> = [];
        for (const uuid of uuids) {
            const trialObservable = this.getSingleTrialData(uuid);
            const screenshotObservable = this.getSingleTrialScreenshots(uuid);
            result.push(
                forkJoin([trialObservable, screenshotObservable])
                    .pipe(map(i => ({
                        uuid,
                        trial: i[0],
                        screenshot: i[1]
                    })))
            );
        }
        return forkJoin(...result);
    }

    resetAcceptedOffers() {
        this.http.put(`${environment.apiUrl}/offers/reset_accepted`, null)
            .subscribe(() => {
                console.log('All offers have been reset.');
            });
    }

    private handleError(error: HttpErrorResponse) {
        console.log(error);
        this.alertService.error('An error occurred. See console for debugging.');
        return throwError(error);
    }
}
