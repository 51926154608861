<div fxLayout="row">
    <div class="container" fxFlex="70%">
        <form [formGroup]="onboardingForm" (ngSubmit)="onSubmit()">
            <h3 class="heading">{{ 'ONBOARDING.title' | translate }}</h3>

            <div class="form-group">
                <mat-form-field id="firstName" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.firstName' | translate }}</mat-label>
                    <input matInput formControlName="firstName"/>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="lastName" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.lastName' | translate }}</mat-label>
                    <input matInput formControlName="lastName"/>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="email" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.email' | translate }}</mat-label>
                    <input matInput formControlName="email"/>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="telephone" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.telephone' | translate }}</mat-label>
                    <input matInput formControlName="telephone"/>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="birthday" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.birthday' | translate }}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="birthday"/>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="country" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.country' | translate }}</mat-label>
                    <mat-select formControlName="country"  multiple>
                        <mat-option *ngFor="let item of countryList" [value]="item">
                            {{item.country}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="gender" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.gender' | translate }}</mat-label>
                    <mat-select formControlName="gender" multiple>
                        <mat-option *ngFor="let item of genderList" [value]="item">
                            {{item.gender}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="language" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.language' | translate }}</mat-label>
                    <mat-select formControlName="language" multiple>
                        <mat-option *ngFor="let item of languageList" [value]="item">
                            {{item.language}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <h3 class="heading">{{ 'ONBOARDING.privacyTitle' | translate }}</h3>
            <div class="form-group">
                <mat-form-field id="post_privacy" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.postPrivacy' | translate }}</mat-label>
                    <mat-select formControlName="postPrivacy">
                        <mat-option *ngFor="let item of privacyService.privacySettings" [value]="item['index']">
                            {{item['label']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="profile_privacy" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.profilePrivacy' | translate }}</mat-label>
                    <mat-select formControlName="profilePrivacy">
                        <mat-option *ngFor="let item of privacyService.privacySettings" [value]="item['index']">
                            {{item['label']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="activity_privacy" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.activityPrivacy' | translate }}</mat-label>
                    <mat-select formControlName="activityPrivacy">
                        <mat-option *ngFor="let item of privacyService.privacySettings" [value]="item['index']">
                            {{item['label']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field id="search_privacy" class="onboarding-form-field">
                    <mat-label i18n>{{ 'ONBOARDING.searchPrivacy' | translate }}</mat-label>
                    <mat-select formControlName="searchPrivacy">
                        <mat-option *ngFor="let item of privacyService.privacySettings" [value]="item['index']">
                            {{item['label']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button
                    class="onboarding-button"
                    [disabled]="!isLoaded || hasFormErrors"
                    mat-raised-button
                    color="primary"
                    i18n
            >
                {{ 'ONBOARDING.submit' | translate }}
            </button>
        </form>
    </div>
    <div class="container" fxFlex="30%">
        <div class="carousel-style" fxLayout="column">
            <mat-card class="onboard-card">
                <mat-card-title>Welcome!</mat-card-title>
                <mat-card-content>
                    <p>{{ 'ONBOARDING.introduction' | translate }}</p>
                </mat-card-content>
            </mat-card>
            <mat-card class="onboard-card">
                <mat-card-title>Your privacy</mat-card-title>
                <mat-card-content>
                    <p>{{ 'ONBOARDING.privacyIntroduction' | translate }}</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
