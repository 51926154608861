import {AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SpecialOffer} from '../../models/specialoffer.model';
import {OfferService} from '../../services/offer.service';
import {AlertService} from '../../services/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {OfferAcceptDialog} from '../offer-accept-dialog/offer-accept-dialog.component';
import {UploadService} from '../../services/upload.service';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {Post} from '../../models/post.model';
import {UserService} from '../../authentication/services';
import {TrialService} from '../../services/trial.service';

declare let $: any;
@Component({
    selector: 'app-specialoffer',
    templateUrl: './specialoffer.component.html',
    styleUrls: ['./specialoffer.component.css']
})
export class SpecialOfferComponent implements OnInit {
  @Input() offer: SpecialOffer;
  @Output() offerUpdated = new EventEmitter<SpecialOffer>();
  constructor(
    private offerService: OfferService,
    private uploadService: UploadService,
    private alertService: AlertService,
    private userService: UserService,
    private trialService: TrialService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }
  onRejectClick(event: MouseEvent | null) {
      // Two cases: explicit reject + timeout reject
      /*
      if (event) {
          this.trialService.recordExternalButtonFrameAndScreenshot('offer_reject', this.offer.id.toString(), event);
      }
       */
      this.offerService.rejectOffer(this.offer)
          .then(response => {
              if (response.status === 200) {
                  this.offerUpdated.emit(this.offer);
              }
          }, error => this.handleError(error));
  }

  onAcceptClick(event: MouseEvent) {
      const dialogRef = this.dialog.open(OfferAcceptDialog, {
          data: this.offer
      });
      this.trialService.recordExternalButtonFrameAndScreenshot('offer_accept_dialog', this.offer.id.toString(), event);
      // dialogRef.afterOpened().subscribe(response =>)
      dialogRef.afterClosed().subscribe(result => {
          if (result) {
              if (result.success) {
                  /*
          TODO:
          There is a race bug here: since accepting an offer & posting is done separately,
          when the offer is accepted twice, even if the error message is displayed and the offer
          is not accepted, the post will still be made successfully.
           */
                  Promise.all([
                      new Promise((resolve, reject) => {
                          this.offerService.acceptOffer(this.offer)
                              .then(response => {
                                  resolve(response);
                              })
                              .catch(e => reject(e));
                      }),
                      new Promise<number>((resolve, reject) => {
                          const p: Post = {
                              createdAt: null,
                              deleted: false,
                              demonetized: false,
                              description: result.postText,
                              euroCounter: 0,
                              hashtags: result.hashtags ? result.hashtags.split(' ') : [],
                              id: -1,
                              imageUrl: null,
                              image: this.offer.images[result.photo],
                              location: '',
                              name: this.userService.currentUserValue.firstname + ' '
                      + this.userService.currentUserValue.lastname,
                              postVisibility: 'everyone',
                              profileIcon: '',
                              username: this.userService.currentUserValue.username,
                              votes: []
                          };
                          this.uploadService.addPost(p)
                              .subscribe(response => {
                                  resolve(response);
                              }, error => reject(error));
                      })
                  ]).then(response =>
                      this.offerService.relateOfferToPost(this.offer, response[1])
                  ).then(response => {
                      if (response.status === 200) {
                          this.offerUpdated.emit(this.offer);
                          this.userService.updateCurrentUserInfo()
                              .subscribe(
                                  // data => window.location.reload()
                                  // we cannot reload the component here since it would interrupt input data collection.
                              );
                      }
                  }).catch(e => this.handleError(e));
              } else {
                  this.alertService.error('Timeout! This offer has been rejected.');
                  this.onRejectClick(null);
              }
          }
      });
  }

  private handleError(error: HttpErrorResponse) {
      if (error.status === 404) {
          this.alertService.error('Offer does not exist!');
      } else if (error.status === 409) {
          this.alertService.error('You have already accepted or rejected this offer!');
      } else {
          this.alertService.error('Unknown error');
      }
      return throwError('Something bad happened; please try again later.');
  }

}
