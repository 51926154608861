
/**
 * This TypeScript "ActivityType" string enum must be one-on-one mapped to the backend ActivityType enum.
 */
// eslint-disable-next-line no-shadow
export enum ActivityType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
  ONBOARD = 'ONBOARD',
  CLICK_TAB = 'CLICK_TAB',
  UPVOTE_POST = 'UPVOTE_POST',
  DOWNVOTE_POST = 'DOWNVOTE_POST',
  DELETE_POST = 'DELETE_POST',
  MONETIZE_POST = 'MONETIZE_POST',
  SHARE_POST = 'SHARE_POST',
  UPLOAD_POST = 'UPLOAD_POST',
  MODIFY_PROFILE = 'MODIFY_PROFILE',
  ACCESS_PROFILE = 'ACCESS_PROFILE',
  ACCESS_HASHTAG = 'ACCESS_HASHTAG',
  SEARCH = 'SEARCH',
  ACCESS_OFFER = 'ACCESS_OFFER',
  REJECT_OFFER = 'REJECT_OFFER',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  POST_OFFER = 'POST_OFFER',
  DISMISS_OFFER = 'DISMISS_OFFER',
  VOTE_WITNESS = 'VOTE_WITNESS',
  EXPORT_PRIVATEKEY = 'EXPORT_PRIVATEKEY',
}

export interface Activity {
  id: number;
  activityType: ActivityType;
  user: number;
  param1?: string;
  param2?: string;
  param3?: string;
  param4?: string;
  param5?: string;
}
