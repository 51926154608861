import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-multiple-image-select',
    templateUrl: './multiple-image-select.component.html',
    styleUrls: ['./multiple-image-select.component.css']
})
export class MultipleImageSelectComponent implements OnInit {
    @Output() imageSelected = new EventEmitter<string[]>();

    selectedFiles?: FileList;
    selectedFileNames: string[] = [];
    previews: string[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    selectFiles(event: any): void {
        this.selectedFileNames = [];
        this.selectedFiles = event.target.files;
        this.previews = [];
        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    this.previews.push(e.target.result);
                };
                reader.readAsDataURL(this.selectedFiles[i]);
                this.selectedFileNames.push(this.selectedFiles[i].name);
            }
        }
        this.imageSelected.emit(this.previews);
    }
    getSelectedFilesList(): FileList | undefined {
        return this.selectedFiles;
    }
    getSelectedFilesEncoded(): string[] {
        return this.previews;
    }
    clearSelectedFiles(): void {
        this.selectedFileNames = [];
        this.selectedFiles = null;
        this.previews = [];
    }
}
