<mat-card class="post_card">
    <mat-card-header>
        <div class="activity_stream_avatar" mat-card-avatar>
            <mat-icon>face</mat-icon>
        </div>
        <mat-card-title>
            <div class="name_text">
                <a>
                    {{ offer.corporateUser.name }}
                </a>
            </div>
        </mat-card-title>
        <mat-card-subtitle>
            <div class="category_text">{{ offer.offerCategory }}</div>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="offer_text_container">
        <p class="offer_text">{{ offer.text }}</p>
    </mat-card-content>
    <mat-card-content>
        <div fxLayout="row">
            <div mat-card-avatar>
                <mat-icon>face</mat-icon>
            </div>
            <div>
                <p>{{ offer.value }}{{ 'SPECIALOFFER.value' | translate }}</p>
            </div>
        </div>
    </mat-card-content>
    <!-- Buttons -->
    <mat-card-content>
        <mat-card-actions class="mat-icon-button">
            <button (click)="onRejectClick($event)" mat-raised-button class="sp_button" color="secondary">
                {{ 'SPECIALOFFER.reject' | translate }}
            </button>
            <span class="fill-remaining-space"></span>
            <button (click)="onAcceptClick($event)" mat-raised-button class="sp_button" color="primary">
                {{ 'SPECIALOFFER.accept' | translate }}
            </button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>

