/*
Personal Profile Page of the current user
Personal Info accessed via the authentication service (User assigned to variable currentUser)
Personal Posts are called via post service
Personal Activity Feed is called via activity service
*/

import {Component, OnInit} from '@angular/core';
import {Post} from '../../models/post.model';
import {UserService, User} from '../../authentication/services';
import {PostService} from '../../services/postAction.service';
import {MatDialog} from '@angular/material/dialog';
import {ShowPublicKeyDialogComponent} from './public-key/show-public-key.dialog';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public currentUser: User = null;
  public posts: Post[] = [];

  constructor(
      userService: UserService,
    private postService: PostService,
    private dialog: MatDialog
  ) {
      this.currentUser = userService.currentUserValue;
      this.postService
          .getMyPosts(this.currentUser.username)
          .subscribe(data => (this.posts = data));
  }

  ngOnInit() {
  }

  /* Opens the dialog that stores the Users Public Key */
  onShowPublicKeyClick = () => {
      this.dialog.open(ShowPublicKeyDialogComponent, {
          width: '50%',
          data: { publicKey: this.currentUser.publicKey }
      });
  };

  onPostUpdated = () => {};
}
