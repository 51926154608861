import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../authentication/services';

@Component({
    selector: 'app-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent implements OnInit {

  @Input() inputUser: User;

  constructor(

  ) { }

  ngOnInit(): void {

  }

}
