<!-- HTML displayed on pop-up dialog -->
<h1 mat-dialog-title i18n>Privacy Settings in a blockchain environment</h1>
<div mat-dialog-content>
  <p class="mat-body">{{data.message}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" i18n>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial i18n>
    OK
  </button>
</div>
