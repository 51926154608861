import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User, UserService} from '../authentication/services';
import {Post} from '../models/post.model';
import {AlertService} from './alert.service';
import {environment} from 'src/environments/environment';
import {ActivityService} from './activity.service';
import {Activity} from '../models/activity.model';

@Injectable({
    providedIn: 'root'
})
export class PostService {
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private alertService: AlertService,
        private activityService: ActivityService
    ) {}

    // Returns all posts except those the current user deleted
    getAllPosts(unused: string): Observable<Post[]> {
        const id = this.userService.currentUserValue.username;
        return this.http.get<Post[]>(`${environment.apiUrl}/posts`).pipe(
            map(all =>
                all.filter(p => {
                    if (p.deleted) {
                        return p.username !== id;
                    } else {
                        return true;
                    }
                })
            )
        );
    }

    // Returns all posts for the given user id
    getMyPosts(id: string): Observable<Post[]> {
        return this.getAllPosts('').pipe(
            map(all => all.filter(p => p.username === id)),
            map(all => all.filter(p => p.deleted === false))
        );
    }

    // Delete a post for the current user. The post remains visible for other users.
    deletePost = (post: Post) => {
        post.deleted = true;
        return this.http
            .delete(`${environment.apiUrl}/posts/${post.id}`)
            .subscribe(() => {
                this.alertService.notify(`The post with id ${post.id} was deleted.`);
            });
    };

    sharePost = (user: User, post: Post) => this.http
        .put(`${environment.apiUrl}/posts/${post.id}`, post)
        .subscribe(() => {
            // TODO: record activity
            this.alertService.notify(`Your post was shared by ${user.username}.`);
        });

    demonetizePost = (user: User, post: Post) => {
        post.demonetized = true;
        return this.http
            .put(`${environment.apiUrl}/posts/${post.id}`, post)
            .subscribe(() => {
                // TODO: record activity
            });
    };
}
