<div fxLayout="row">
  <div
    fxLayout="column"
    fxLayoutAlign="space-aroung center"
    fxFill
    fxLayoutGap="20px"
    id="wrapper"
  >
    <div class="imageInput">
      <p id="upload-form-title" class="mat-headline" i18n>
        {{ 'UPLOAD.upload' | translate }}:
      </p>

      <!-- Upload-Funktion, kann man noch schöner machen evtl. -->
      <div>
        <input
          type="file"
          #Image
          accept="image/*"
          (change)="handleFileInput($event.target.files)"
          name="image"
          required
        />
      </div>

      <!-- Image Preview, Filters could be implemented here -->
      <div class="image preview mb-3">
        <img [src]="image" style="width:500px; height:500px" />
      </div>
    </div>

    <!-- visibility settings -->
    <mat-form-field class="privacySelect upload-form-field">
      <mat-label>{{ 'UPLOAD.visibility' | translate }}</mat-label>
      <mat-select [(value)]="selected" (valueChange)="onPrivacySelect()">
        <mat-option value="everybody">{{ 'UPLOAD.everybody' | translate}}</mat-option>
        <mat-option value="friends of friends">{{ 'UPLOAD.friendsOfFriends' | translate}}</mat-option>
        <mat-option value="friends">{{ 'UPLOAD.friends' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Location Inputfield -->
    <mat-form-field class="upload-form-field">
      <mat-label i18n><mat-icon>location_on</mat-icon>{{ 'UPLOAD.location' | translate}}</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #location
        (input)="updateLocation(location.value)"
      ></textarea>
    </mat-form-field>

    <!-- Description Inputfield -->
    <mat-form-field class="upload-form-field" appearance="outline">
      <mat-label><mat-icon i18n>Title</mat-icon>{{ 'UPLOAD.description' | translate}}</mat-label>
      <textarea
        matInput
        #description
        (input)="updateDescription(description.value)"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
      ></textarea>
    </mat-form-field>

    <!-- Hashtag Inputfield -->

    <mat-form-field class="upload-form-field">
      <mat-label i18n># {{ 'UPLOAD.addHashtags' | translate}}</mat-label>
      <textarea
        matInput
        #hashtag
        (input)="updateHashtag(hashtag.value)"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
      ></textarea>
    </mat-form-field>

    <!-- Upload/Post Button -->
    <button
      mat-button
      class="addButton"
      color="primary"
      (click)="onUpload()"
      [disabled]="Image.value == ''"
      i18n
    >
      <mat-icon>add</mat-icon>{{ 'UPLOAD.post' | translate}}
    </button>
  </div>
</div>
