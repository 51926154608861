<mat-card class="activity_stream">
    <mat-card-header>
        <mat-icon matListIcon>public</mat-icon>
        <mat-card-title i18n>{{ 'HOME.recentActivity' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-list>
        <button
                mat-list-item
                class="notifications"
                *ngFor="let item of allActivities; let i = index"
        >
            <mat-icon matListIcon>{{ this.allActivities[i].icon }}</mat-icon>
            {{ this.allActivities[i].description | translate }}
        </button>
    </mat-list>
</mat-card>