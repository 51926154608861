<body>
<div class="container">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="70">
        <!-- Profile Info section -->

        <div class="item_info">
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar class="activity_stream_avatar_info">
                        <mat-icon>face</mat-icon>
                    </div>
                    <mat-card-title id="profile-info-text">
                        <div class="mat-h3" *ngIf="isLoaded" i18n>
                            {{ 'USERPROFILES.profile' | translate }}: @{{ currentUsername }}
                        </div>
                        <div class="mat-body" *ngIf="isLoaded">
                            {{ currentUserProfile.firstname }}
                            {{ currentUserProfile.lastname }} ({{ currentUserProfile.age }})
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-card-actions align="end" fxLayout="row">
                        <button mat-flat-button *ngIf="isLoaded" i18n>
                            {{ 'USERPROFILES.joined' | translate }}
                            {{ this.dateOfRegistration.toLocaleDateString() }}
                        </button>
                        <button mat-flat-button *ngIf="isLoaded">
                            <mat-icon>location_on
                            </mat-icon
                            >
                            {{ currentUserProfile.country }}
                        </button>
                        <button mat-flat-button *ngIf="isLoaded" i18n>
                            {{ currentUserProfile.followers }} Followers
                        </button>
                        <button mat-flat-button *ngIf="isLoaded" i18n>
                            {{ currentUserProfile.following }} {{ 'USERPROFILES.following' | translate }}
                        </button>
                        <button mat-flat-button i18n>{{ 'USERPROFILES.follow' | translate }}</button>
                    </mat-card-actions>
                </mat-card-content>
            </mat-card>
        </div>

        <!-- Feed section -->

        <div class="item_feed" fxFlex>
            <mat-card>
                <mat-card-header>
                    <mat-card-title *ngIf="isLoaded" i18n>
                        {{ currentUserProfile.firstname }}'s Feed
                    </mat-card-title
                    >
                </mat-card-header>
            </mat-card>
            <app-post
                    *ngFor="let item of posts; let i = index"
                    [post]="posts[i]"
            ></app-post>
        </div>
    </div>

    <!-- Wallet section -->

    <div fxLayout="column" fxFlex>
        <div class="item_wallet">
            <mat-card class="profile_card">
                <mat-card-header>
                    <mat-card-title *ngIf="isLoaded" i18n>
                        {{ currentUserProfile.firstname }}'s Wallet
                    </mat-card-title
                    >
                    <div mat-card-avatar>
                        <mat-icon>euro</mat-icon>
                    </div>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div class="mat-body"></div>
                    <div class="profile_additional_information_entry">
                        <p class="mat-h3" id="profile_voting_power_text" i18n>
                            Voting- Power
                        </p>
                        <p id="profile_voting_power_value" *ngIf="isLoaded">
                            {{ currentUserProfile.votingPower }}%
                        </p>
                        <mat-progress-bar
                                class="profile_voting_power"
                                mode="determinate"
                                [value]="currentUserProfile.votingPower"
                                *ngIf="isLoaded"
                        ></mat-progress-bar>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="profile_additional_information_entry">
                        <p class="mat-h3" id="profile_balance_text" i18n>{{ 'USERPROFILES.balance' | translate }}</p>
                        <p id="profile_balance_value" *ngIf="isLoaded">
                            {{ currentUserProfile.balance && currentUserProfile.balance }} €
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
</body>
