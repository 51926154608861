import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-exchange-rate',
    templateUrl: './exchange-rate.component.html',
    styleUrls: ['./exchange-rate.component.css']
})
export class ExchangeRateComponent implements OnInit {

  public currentMCExchange = 0.04;
  public barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      layout: {
          padding: {
              top: 25
          }
      },
      scales: {
          yAxes: [
              {
                  display: true,
                  scaleLabel: {
                      display: true,
                      labelString: '1MC in $'
                  },
                  ticks: {
                      beginAtZero: true,
                      stepSize: 25
                  }
              }
          ]
      }
  };
  public barChartLabels = [
      moment()
          .subtract(4, 'days')
          .format('MM/DD'),
      moment()
          .subtract(3, 'days')
          .format('MM/DD'),
      moment()
          .subtract(2, 'days')
          .format('MM/DD'),
      moment()
          .subtract(1, 'days')
          .format('MM/DD'),
      moment().format('MM/DD')
  ];
  public barChartLegend = false;
  public barChartData = [
      {
          data: [65, 59, 80, 81, 56, 55, this.currentMCExchange],
          label: 'MC'
      }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
