import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import {ValidateProfilesService} from '../services/validateProfiles.service';
import {User, UserService} from '../services';
import {MultipleProfileService} from 'src/app/services/multipleProfile.service';
import {PostService} from 'src/app/services/postAction.service';
import {Post} from 'src/app/models/post.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-user',
    templateUrl: './userProfiles.component.html',
    styleUrls: ['./userProfiles.component.css']
})

export class UserProfilesComponent implements OnInit, OnDestroy {
  currentUserProfile: User = null;
  posts: Post[];
  currentUsername: string;
  isLoaded: boolean;
  dateOfRegistration: Date;
  private sub: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private validateProfiles: ValidateProfilesService,
    private router: Router,
    private userService: UserService,
    private postService: PostService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
      this.sub = this.activatedRoute.paramMap.subscribe(params => {
          this.currentUsername = params.get('username');
          this.selectCurrentProfile();
          this.selectCurrentProfilePosts();
      });


  }


  /* Method verifies that the username is in fact a possible username.
  calls validateProfiles service to check this. */

  verifyUsername(data: string) {
      if (data && this.validateProfiles.checkUsername(data)) {
          this.currentUsername = data;
      } else {
          this.router.navigate(['']);
      }
  }

  /* method that should import users from user service,
  which has caused an unresolved error, as explained above. */

  /*
  async loadAllUsers() {
      this.users = await this.userService.getAll().toPromise();
  }
  */

  selectCurrentProfile() {
      console.log('Setting profile');
      this.userService.getUser(this.currentUsername).subscribe(
          data => {
              this.currentUserProfile = data;
              this.dateOfRegistration = new Date(this.currentUserProfile.dateOfRegistration);
              this.isLoaded = true;
          },
          error => {
              this.snackBar.open('Unable to load user profile!', 'OK', {
                  duration: 4000
              });
          });
  }

  selectCurrentProfilePosts() {
      this.postService
          .getMyPosts(this.currentUsername)
          .subscribe(data => (this.posts = data));
  }

  ngOnDestroy() {
      this.sub.unsubscribe();
  }

}
