<head>
  <meta name="author" content="Christina Speck">
</head>
<div fxLayout="row">
  <div class="container-login-left" fxFlex="20%">
  </div>
  <!-- Logo and Slogan, inspired by facebook-login -->
  <div class="container-login-left" fxFlex="30%">
    <div class="container-login-vertical">
      <img src="assets/MatgramLogo.png" width="200px"/>
      <div class="infotextHeader mat-h4" i18n>{{ 'LOGIN.slogan' | translate }}</div>
    </div>
  </div>
  <!-- Form fields for login data, login and register button -->
  <div class="container-login" fxFlex="30%">
    <div class="container-login-right">
    <div class="container-login-vertical">
    <mat-card class="login-card">
      <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-form-field id="user-id" class="login-form-field">
            <mat-label i18n>{{ 'LOGIN.username' | translate }}</mat-label>
            <input matInput formControlName="username" />
            <mat-error *ngIf="f.username.invalid" i18n>
              {{ 'LOGIN.usernameInvalid' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field id="password" class="login-form-field">
            <mat-label i18n>{{ 'LOGIN.password' | translate }}</mat-label>
            <input matInput type="password" formControlName="password" />
            <mat-error *ngIf="f.password.errors" i18n>
              {{ 'LOGIN.passwordErrors' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <button
                class="login-form-field-button"
                color="primary"
                mat-raised-button
                i18n
        >
          {{ 'LOGIN.login' | translate }}
        </button>
      </form>
      <a routerLink="/register" i18n>
        <button class="login-form-field-button" color="primary" mat-raised-button>
          {{ 'LOGIN.register' | translate }}
        </button>
      </a>
      </mat-card-content>
    </mat-card>
    </div>
    </div>
  </div>
</div>
