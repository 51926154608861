/*
 Service receives a username when a post is clicked
 (with the intention to view somebodys profile) and
 returns that username on geSelectedProfile()
 */

import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class MultipleProfileService {
    public multipleProfileSubject = new Subject<string>();

    private currentUserProfile: string;

    constructor() {}

    selectUserProfile(username: string) {
        this.currentUserProfile = username;
        this.multipleProfileSubject.next(username);
    }

    getSelectedProfile(): Observable<string> {
        return of(this.currentUserProfile);
    }
}
