<body>
<div class="container">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="70">
        <div class="item_info">
            <mat-card>
                <mat-card-title>
                    Hashtag:
                    <app-hashtag-button [tag]="currentHashtag"></app-hashtag-button>
                </mat-card-title>
            </mat-card>
        </div>
        <div class="item_feed" fxFlex>
            <mat-card>
                <mat-card-header>
                    <mat-card-title *ngIf="isLoaded" i18n>
                        Posts under {{ '#' + currentHashtag }}
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
            <app-post
                    *ngFor="let item of posts; let i = index"
                    [post]="posts[i]"
            ></app-post>
        </div>
    </div>
</div>
</body>
