import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Post} from '../models/post.model';

@Injectable({
    providedIn: 'root'
})
export class HashtagService {
    constructor(
    private http: HttpClient
    ) {
    }
    getAllTags(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.apiUrl}/hashtags/all`);
    }

    getPostsByHashtag(tag: string): Observable<Post[]> {
        return this.http.get<Post[]>(`${environment.apiUrl}/hashtag/${tag}`);
    }
}
