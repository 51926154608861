import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Country, Gender, Language} from '../models/profileParam.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileParamService {

    constructor(
    private http: HttpClient
    ){

    }
    getAllCountry(): Observable<Country[]> {
        return this.http.get<Country[]>(`${environment.apiUrl}/country/all`);
    }
    getAllGender(): Observable<Gender[]> {
        return this.http.get<Gender[]>(`${environment.apiUrl}/gender/all`);
    }
    getAllLanguage(): Observable<Language[]> {
        return this.http.get<Language[]>(`${environment.apiUrl}/language/all`);
    }
}
