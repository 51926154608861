<div class="container">
  <!-- Left section -->

  <div class="voting_section" fxFlex="50%">
    <h2 class="mat-headline" i18n>{{ 'WITNESS.voteFor' | translate }}!</h2>
    <p class="mat-subheading-2" i18n>
      {{ 'WITNESS.actualVotes' | translate }}.
    </p>
    <p class="mat-caption" i18n>
      <mat-icon class="info_button">info</mat-icon>
      {{ 'WITNESS.votingDisabled' | translate }}
    </p>

    <!-- Table -->

    <mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="rank">
        <mat-header-cell *matHeaderCellDef i18n> {{ 'WITNESS.rank' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.rank }}
          <button mat-button class="vote_button">
            <mat-icon>eject</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="witness">
        <mat-header-cell *matHeaderCellDef i18n> {{ 'WITNESS.witness' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.username }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="votes">
        <mat-header-cell *matHeaderCellDef i18n> {{ 'WITNESS.votes' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.votes }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="information">
        <mat-header-cell *matHeaderCellDef i18n> {{ 'WITNESS.information' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-raised-button color="primary" (click)="profileClick(element.username)">Profile</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <!--  Info section (text) -->
  <div class="info_section" fxFlex="50%">
    <h2 class="mat-headline" i18n>{{ 'WITNESS.how' | translate }}?</h2>
    <p class="mat-subheading-2" i18n>
      {{ 'WITNESS.howExplanation' | translate }}
    </p>
    <h2 class="mat-headline" i18n>{{ 'WITNESS.what' | translate }}?</h2>
    <p class="mat-subheading-2" i18n>
      {{ 'WITNESS.whatExplanation' | translate }}
    <li>{{ 'WITNESS.whatList1' | translate}}</li>
    <li>{{ 'WITNESS.whatList2' | translate}}</li>
    <li>{{ 'WITNESS.whatList3' | translate}}</li>
    </p>
    <h2 class="mat-headline" i18n>{{ 'WITNESS.become' | translate}}</h2>
    <p class="mat-subheading-2" i18n>
      {{ 'WITNESS.becomeExplanation' | translate}}
    </p>
  </div>
</div>
