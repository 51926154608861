<!-- HTML displayed on dialog -->
<div mat-dialog-content>
  <p class="mat-body" i18n>
    {{ 'KEYVER.provideKey' | translate }}
  </p>
  <mat-form-field id="privateKey" class="example-full-width">
    <textarea
      matInput
      [(ngModel)]="data.privateKey"
      placeholder="private key"
      cdkTextareaAutosize
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" i18n>{{ 'KEYVER.cancel' | translate }}</button>
  <button mat-button (click)="onVerifyClick()" cdkFocusInitial>
    {{ 'KEYVER.verify' | translate }}
  </button>
</div>
