<mat-card>
    <mat-card-header>
        <mat-card-title>Experiment Control</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button mat-raised-button color="primary" (click)="startRecording()">Start</button>
        <button mat-raised-button color="primary" (click)="stopRecording()">Stop</button>
        <button mat-raised-button color="primary" (click)="playRecording()">Replay</button>
        <button mat-raised-button color="primary" (click)="outputRecordingToConsole()">Output</button>
        <button mat-raised-button color="primary" (click)="sendToBackend()">Send</button>
        <button mat-raised-button color="primary" (click)="showLoadDialog()">Load</button>
        <button mat-raised-button color="primary" (click)="resetAcceptedOffers()">Reset Offers</button>
        <button mat-raised-button color="primary" (click)="screenshot()">Screenshot</button>
    </mat-card-content>
</mat-card>
