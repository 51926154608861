import {RouterModule} from '@angular/router';

import {LoginComponent} from './authentication/login';
import {RegisterComponent} from './authentication/register';
import {AuthGuard, ObserverAuthGuard} from './authentication/helpers';
import {HomeComponent} from './tabs/home/home.component';
import {SearchComponent} from './tabs/search/search.component';
import {ProfileComponent} from './tabs/profile/profile.component';
import {WitnessComponent} from './tabs/witness/witness.component';
import {UploadComponent} from './tabs/upload/upload.component';
import {UserProfilesComponent} from './authentication/userProfiles/userProfiles.component';
import {HashtagSearchComponent} from './tabs/hashtag-search/hashtag-search.component';
import {HashtagComponent} from './shared/hashtag/hashtag.component';
import {OnboardingComponent} from './authentication/onboarding/onboarding.component';
import {SpecialOfferHomeComponent} from './tabs/offerhome/offerhome.component';
import {TrialOverviewComponent} from './observer_uis/trial-overview/trial-overview.component';
import {OfferCreateComponent} from './observer_uis/offer-create/offer-create.component';
import {TrialDetailComponent} from './observer_uis/trial-detail/trial-detail.component';


export const Routing = RouterModule.forRoot([
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'offers', component: SpecialOfferHomeComponent, canActivate: [AuthGuard] },
    { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'hashtagSearch', component: HashtagSearchComponent, canActivate: [AuthGuard] },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'witness', component: WitnessComponent, canActivate: [AuthGuard] },
    { path: 'upload', component: UploadComponent, canActivate: [AuthGuard] },
    { path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard] },
    { path: 'userProfiles/:username', component: UserProfilesComponent, canActivate: [AuthGuard] },
    { path: 'hashtag/:hashtag', component: HashtagComponent, canActivate: [AuthGuard] },
    { path: 'control/trialOverview', component: TrialOverviewComponent, canActivate: [ObserverAuthGuard]},
    { path: 'control/trial/view', component: TrialDetailComponent, canActivate: [ObserverAuthGuard]},
    { path: 'control/offer/create', component: OfferCreateComponent, canActivate: [ObserverAuthGuard]},
    { path: '**', redirectTo: '' },
], { relativeLinkResolution: 'legacy' });
