// Component managing register data, openening key dialog, redirecting to login
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {catchError} from 'rxjs/operators';

import {UserService} from '../services';
import {ErrorStateMatcher} from '@angular/material/core';
import {KeySetupDialogComponent} from '../key-setup/key-setup.dialog';
import {MatDialog} from '@angular/material/dialog';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AlertService} from '../../services/alert.service';

// Custom error state matcher to display if passwords match
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(
            control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
        );

        return invalidCtrl || invalidParent;
    }
}

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public currentUser: Observable<any>;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  matcher = new MyErrorStateMatcher();
  publicKey = '';

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
  ) {
      // redirect to home if already logged in
      if (this.userService.currentUserValue) {
          this.router.navigate(['home']);
      }
  }

  // identify which form errors
  get formErrors() {
      return this.registerForm.errors;
  }

  // identify if there are form errors
  get hasFormErrors() {
      return (
          Object.keys(this.registerForm.controls)
              .map(key => this.registerForm.get(key).invalid)
              .find(invalid => invalid === true) || this.registerForm.errors
      );
  }

  // convenience getter for easy access to form fields
  get f() {
      return this.registerForm.controls;
  }

  ngOnInit() {
      this.registerForm = this.formBuilder.group(
          {
              username: ['', Validators.required],
              password: ['', [Validators.required, Validators.minLength(6)]],
              passwordRepeat: ['', []],
              balance: [0]
          },
          {validators: this.passwordsMatchValidator}
      );
  }

  // Key generation dialog
  onSubmit = () => {
      window.crypto.subtle
          .generateKey(
              {
                  name: 'RSA-PSS',
                  modulusLength: 1024,
                  publicExponent: new Uint8Array([1, 0, 1]),
                  hash: 'SHA-256'
              },
              true,
              ['sign', 'verify']
          )
          .then(response => {
              this.exportPrivateKey(response.privateKey).then(privateKey => {
                  const keySetupDialog = this.dialog.open(KeySetupDialogComponent, {
                      width: '60%',
                      data: {privateKey}
                  });
                  keySetupDialog.afterClosed().subscribe((confirmed: string) => {
                      if (confirmed) {
                          this.exportPublicKey(response.publicKey).then(publicKey =>
                              this.doRegister(publicKey)
                          );
                      }
                  });
              });
          });
  };

  ab2str = buffer => String.fromCharCode.apply(null, new Uint8Array(buffer));

  exportPublicKey = async key => {
      const exported = await window.crypto.subtle.exportKey('spki', key);
      const keydataS = this.ab2str(exported);
      const keydataB64 = window.btoa(keydataS);
      return keydataB64;
  };

  exportPrivateKey = async key => {
      const exported = await window.crypto.subtle.exportKey('pkcs8', key);
      const exportedAsString = this.ab2str(exported);
      const exportedAsBase64 = window.btoa(exportedAsString);

      return exportedAsBase64;
  };

  doRegister = publicKey => {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }

      this.loading = true;
      this.userService
          .register(this.registerForm.value, publicKey)
          .pipe(catchError(this.handleError.bind(this)))
          .subscribe(
              response => {
                  if (response.status === 200) {
                      this.alertService.success('Registration successful', true);
                      this.router.navigate(['login'], {
                          queryParams: {registered: true}
                      });
                  } else {
                      this.alertService.error('Unknown Error');
                  }
              },
          );
  };

  // function comparing passwords, error (boolean false) if unequal
  private passwordsMatchValidator: ValidatorFn = (
      control: FormGroup
  ): ValidationErrors | null => {
      const password = control.get('password') || {value: ''};
      const passwordRepeat = control.get('passwordRepeat') || {value: ''};

      return password.value !== passwordRepeat.value
          ? {passwordsnotmatching: true}
          : null;
  };

  private handleError(error: HttpErrorResponse) {
      if (error.status === 409) {
          this.alertService.error('Username already registered!');
          this.loading = false;
      } else {
          this.alertService.error('Unknown Error');
      }
      return throwError('Unknown Error');
  }

}
