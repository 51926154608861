import { Component, OnInit } from '@angular/core';
import {TrialService} from '../../services/trial.service';
import {TrialInfo} from '../../models/trial.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-trial-overview',
    templateUrl: './trial-overview.component.html',
    styleUrls: ['./trial-overview.component.css']
})
export class TrialOverviewComponent implements OnInit {
    public trialInfoList: Array<TrialInfo> = [];
    public trialInfoListOriginal: Array<TrialInfo> = [];
    public displayedColumns = ['checkbox', 'uuid', 'username', 'timestamp', 'time_elapsed', 'window_width', 'window_height', 'actions'];
    public trialsSelected: Set<TrialInfo> = new Set();
    public screensSelected: Set<number> = new Set();
    public maxScreenLength = 0;

    constructor(
        private trialService: TrialService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.trialService.getTrialInfoList()
            .subscribe( (trialInfoList) => {
                this.trialInfoListOriginal = trialInfoList;
                this.trialInfoList = trialInfoList;
                for (let i = 0; i < this.trialInfoList.length; i++) {
                    trialInfoList[i].localTime = new Date(trialInfoList[i].timestamp).toLocaleString();
                }
            });
    }

    getSingleTrialData(uuid: string) {
        this.trialService.getSingleTrialData(uuid)
            .subscribe((data) => {
                const json = JSON.stringify(data);
                const x = window.open();
                x.document.open();
                x.document.write('<html><body><pre>'+ json + '</pre></body></html>');
                x.document.close();
            });
    }

    checkboxTrialChanged(isChecked: boolean, uuid: string) {
        if (isChecked) {
            this.trialsSelected.add(this.trialInfoList.find(i => i.uuid === uuid));
        } else {
            this.trialsSelected.delete([...this.trialsSelected].find(i => i.uuid === uuid));
        }
        if (this.trialsSelected.size > 0) {
            this.maxScreenLength = Math.max(...[...this.trialsSelected].map(i => i.screenInfo.length));
        } else {
            this.maxScreenLength = 0;
        }
    }

    usernameFilterChanged(input: string) {
        this.trialInfoList = this.trialInfoListOriginal.filter(
            i => i.username.toLowerCase().includes(input.toLowerCase())
        );
    }

    screenCheckboxChanged(isChecked: boolean, index: number) {
        if (isChecked) {
            this.screensSelected.add(index);
        } else {
            this.screensSelected.delete(index);
        }
    }
    startVisualization() {
        const url = this.router.serializeUrl(this.router.createUrlTree(['control', 'trial', 'view'], {
            queryParams: {
                uuid: [...this.trialsSelected].map(i => i.uuid),
                screen: [...this.screensSelected].sort((a, b) => a - b),
            }
        }));
        window.open(url, '_blank');
    }
}
