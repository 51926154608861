<!-- Reusable post component -->

<mat-card class="post_card">
  <mat-card-header>
    <div mat-card-avatar class="activity_stream_avatar">
      <mat-icon>face</mat-icon>
    </div>
    <mat-card-title>
      <div class="name_text">
        <a routerLink="/userProfiles/{{ post.username }}" (click)="profileSelected()"> {{ post.name }} </a>
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      <div class="location_text">{{ post.location }}</div>
      <div class="visibility_text" i18n>
        {{ 'POST.visibleFor' | translate }}: {{ post.postVisibility }}
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image src="{{ post.image }}" />
  <mat-card-content>
    <p>{{ post.description }}</p>
    <p>
      <app-hashtag-button
              *ngFor="let hashtag of post.hashtags"
              [tag]="hashtag"
      >
      </app-hashtag-button>
    </p>
  </mat-card-content>

  <!-- Buttons -->

  <mat-card-actions class="mat-icon-button">
    <button
            mat-icon-button
            class="btnvote"
            color="{{ upvotedByMe() ? 'primary' : 'secondary' }}"
            matTooltip="Upvotes"
            (click)="onUpvoteClick()"
    >
      <mat-icon>thumb_up</mat-icon>
      {{ getUpvotesCount() }}
    </button>
    <button
            mat-icon-button
            class="btnvote"
            color="{{ downvotedByMe() ? 'primary' : 'secondary' }}"
            matTooltip="Downvotes"
            (click)="onDownvoteClick()"
    >
      <mat-icon>thumb_down</mat-icon>
      {{ getDownvotesCount() }}
    </button>
    <button mat-icon-button matTooltip="{{ 'POST.comment' | translate }}">
      <mat-icon>comment</mat-icon>
    </button>
    <button mat-icon-button matTooltip="{{ 'POST.share' | translate }}">
      <mat-icon>share</mat-icon>
    </button>
    <button
            *ngIf="!isDemonetized(); else is_demonetized"
            mat-icon-button
            matTooltip="Earnings: {{ post.euroCounter }} Reward-Author: ({{
        post.euroCounter / 2
      }}) Reward-Curators: ({{ post.euroCounter / 2 }})
        Payout in 5 days"
    >
      <mat-icon>euro</mat-icon>
    </button>
    <ng-template #is_demonetized>
      <button mat-icon-button matTooltip="Demonetized">
        <mat-icon [ngStyle]="{ color: 'crimson' }">money_off</mat-icon>
      </button>
    </ng-template>
    <button
            *ngIf="isMyPost()"
            mat-icon-button
            matTooltip="{{ 'POST.deletePost' | translate }}"
            (click)="onDeleteClick()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
