<div mat-dialog-title>
    <p class="mat-h2" i18n>
        {{ 'EXPERIMENT_HINT.title' | translate }}
    </p>
</div>
<div mat-dialog-content>
    <p class="mat-body hint-text">{{ experimentHint }}</p>
</div>

<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial i18n>
        OK
    </button>
</div>
