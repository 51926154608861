<mat-form-field>
    <div>
        <mat-toolbar>
            <input matInput [value]="selectedFileNames.length ? selectedFileNames.toString() : 'Select Images'" />
        </mat-toolbar>
        <input
                type="file"
                id="fileInput"
                name="fileInput"
                accept="image/*" multiple
                (change)="selectFiles($event)"
        />
    </div>
</mat-form-field>
<mat-card class="list-card">
    <mat-card-header>
        <mat-card-title>Preview of Images</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let preview of previews; let i = index" class="list-item">
                <p matLine>{{ selectedFileNames[i] }}</p>
                <img [src]="preview" [alt]="selectedFileNames[i]" class="preview" height="50px">
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
