import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class AlertService {
  private subject = new Subject<any>();
  private messages = 0;

  constructor(private router: Router, private snackBar: MatSnackBar) {
      // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
      this.router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
              this.clear();
          }
      });
      this.subject.subscribe(() => (this.messages += 1));
  }

  getMessagesCount = () => this.messages;

  getAlert(): Observable<any> {
      return this.subject.asObservable();
  }

  notify(message: string) {
      this.success(message);
  }

  // Shows a success message to the user
  success(message: string, keepAfterRouteChange = false) {
      this.subject.next({type: 'success', text: message});
      this.openSnackBar(message);
  }

  // Shows errors to the user
  error(message: string, keepAfterRouteChange = false) {
      this.subject.next({type: 'error', text: message});
      this.openSnackBar(message);
  }

  clear() {
      this.subject.next();
      this.messages = 0;
  }

  // Open a snackbar to display a notification
  private openSnackBar = (message: string) =>
      this.snackBar.open(message, 'OK', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
      });
}
