import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
    selector: 'app-hashtag-button',
    templateUrl: './hashtag-button.component.html',
    styleUrls: ['./hashtag-button.component.css']
})
export class HashtagButtonComponent implements OnInit {
  @Input() tag: string;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClick(): void {
      this.router.navigate(['/hashtag', this.tag]);
  }

}
