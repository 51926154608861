/*
Witness Page
Data for the Witness-table is called via the witness service
*/

import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {WitnessService} from '../../services/witness.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-witness',
    templateUrl: './witness.component.html',
    styleUrls: ['./witness.component.css']
})
export class WitnessComponent implements OnInit {
  displayedColumns: string[] = ['rank', 'witness', 'votes', 'information'];
  public dataSource;

  constructor(
    private witnessService: WitnessService,
    private router: Router,
  ) {
  }

  ngOnInit() {
      this.witnessService.getWitnesses().subscribe(
          data => (this.dataSource = new MatTableDataSource(data))
      );
  }

  profileClick(username: string) {
      this.router.navigate(['userProfiles', username]);
  }
}
