import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {Post} from '../../models/post.model';
import {UserService, User} from '../../authentication/services';
import {PostService} from '../../services/postAction.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, AfterViewInit {
    @ViewChild('term')
    private term: ElementRef;
    matches: Post[];
    posts: Post[];
    inProgress: boolean;
    nothingFound: boolean;
    currentUser: User;

    constructor(
        userService: UserService,
        private postService: PostService
    ) {
        this.currentUser = userService.currentUserValue;
        this.postService
            .getAllPosts(this.currentUser.username)
            .subscribe(data => (this.posts = data));
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        fromEvent<any>(this.term.nativeElement, 'keyup')
            .pipe(
                map(event => event.target.value),
                debounceTime(500)
            )
            .subscribe(term => {
                if (term) {
                    const searchTerm = `${term}`;
                    this.inProgress = true;
                    window.setTimeout(() => {
                        this.inProgress = false;
                        this.matches = this.posts.filter(post => post.name.toUpperCase().includes(searchTerm.toUpperCase()));
                        if (this.matches.length === 0) {
                            console.log(`keyup - search for ${term}: nothing found, posts.length = ${this.posts.length}`);
                            console.log(`first post: ${this.posts[0].name}, ${this.posts[0].location}, ${this.posts[0].username}`);
                            this.nothingFound = true;
                        }
                    }, 1000);
                } else {
                    this.nothingFound = false;
                    this.matches = [];
                }
            });
    }

    onSearchTermChange() {
        this.nothingFound = false;
    }

    onPostUpdated = () => {
        this.inProgress = true;

        this.postService.getAllPosts(this.currentUser.username).subscribe(data => {
            console.log(`postService.getAllPosts from user: ${this.currentUser.username}`);
            this.posts = data;
            window.setTimeout(() => {
                this.inProgress = false;
                this.matches = this.posts.filter(post =>
                    post.name.includes(this.term.nativeElement.value)
                );
                if (this.matches.length === 0) {
                    console.log(`postService.getAllPosts - search for ${this.term.nativeElement.value}: nothing found`);
                    this.nothingFound = true;
                }
            }, 500);
        });
    };
}
