// Login page validating user, redirecting to Home
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';

import {UserService} from '../services';
import {AlertService} from '../../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {ExperimentHintComponent} from '../../shared/experiment-hint/experiment-hint.component';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
      // redirect to home if already logged in
      if (this.userService.currentUserValue) {
          if (this.userService.currentUserValue.firstSignIn) {
              this.router.navigate(['onboarding']);
          } else {
              this.router.navigate(['home']);
          }
      }
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

      // display experiment hint
      const isLoginExperimentHintDisplayed = localStorage.getItem('isLoginExperimentHintDisplayed');
      if (!isLoginExperimentHintDisplayed) {
          this.translate.get('EXPERIMENT_HINT.login_hint')
              .subscribe((hintText: string) => {
                  console.log('login hint: ' + hintText);
                  this.dialog.open(ExperimentHintComponent, {
                      data: hintText
                  });
                  localStorage.setItem('isLoginExperimentHintDisplayed', 'true');
              });
      }
  }

  // convenience getter for easy access to form fields
  get f() {
      return this.loginForm.controls;
  }

  onSubmit() {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      // using userService for login data verification
      this.loading = true;
      this.userService
          .login(this.f.username.value, this.f.password.value)
          .pipe(first())
          .subscribe(
              data => {
                  console.log(
                      `User ${this.f.username.value} successfully logged in, redirecting to home`
                  );
                  if (this.userService.currentUserValue) {
                      if (this.userService.currentUserValue.firstSignIn) {
                          this.router.navigate(['onboarding']);
                      } else {
                          this.router.navigate(['home']);
                      }
                  }
              },
              error => {
                  console.log(`Login for user ${this.f.username.value} failed`);
                  console.log(error);
                  this.alertService.error('Login Failed!');
                  this.loading = false;
              }
          );
  }
}
